import React from 'react';
import withCourseNavigation from '../hoc/withCourseNavigation';

const EstimationStrategiesTricks = () => {
  return (
    <div className="course-content">
      <h1 className="course-title">Estimation Strategies and Tricks</h1>

      <section>
        <p>
          When answering estimation questions, there are a few common areas that can trip candidates up. Here, we'll cover strategies to help you:
        </p>
        <ul className="bullet-list">
          <li>Effectively estimate unknown quantities</li>
          <li>Avoid basic math errors</li>
          <li>Gut-check your estimates as you go</li>
        </ul>
      </section>

      <section className="strategy-section">
        <h2 className="section-title">Estimating Unknowns</h2>
        <p>
          In most estimation questions, you'll encounter numbers or quantities you don't know much about. The key is to move past these unknowns quickly and effectively by using strategies like:
        </p>

        <h3>1. Estimate via Proxy</h3>
        <p>
          When you encounter an unfamiliar quantity, use a related figure that you do know and adjust from there. This method is especially useful when dealing with large populations or complex systems.
        </p>
        <p><strong>Example (India): "How many vehicles are there in Delhi?"</strong></p>
        <ul className="bullet-list">
          <li>Estimate Delhi's population: ~30 million</li>
          <li>Assume 1 in 3 households owns a vehicle</li>
          <li>Estimate 5 people per household</li>
          <li>Calculation: 30 million ÷ 5 = 6 million households</li>
          <li>1 in 3 households own a vehicle: 6 million ÷ 3 = 2 million vehicles</li>
        </ul>

        <h3>2. Segment Large Groups</h3>
        <p>
          When you need to estimate for a large or diverse group, it's often easier to break it down into smaller segments.
        </p>
        <p><strong>Example (India): "How many movie tickets are sold in India each week?"</strong></p>
        <ul className="bullet-list">
          <li>Break down into urban (35%, ~490 million) and rural (65%, ~910 million) populations</li>
          <li>Assume urban users buy one ticket every two months</li>
          <li>Assume rural users buy one ticket per year</li>
          <li>Calculate separately for each segment and sum for total</li>
        </ul>

        <h3>3. Use Personal References</h3>
        <p>
          Sometimes, it's helpful to relate an unknown quantity to something familiar in your own life. However, you should always account for bias in these references.
        </p>
        <p><strong>Example (India): "How many people use WhatsApp in India?"</strong></p>
        <ul className="bullet-list">
          <li>Start with your personal experience (e.g., most friends and family use WhatsApp)</li>
          <li>Adjust for outliers (older people, children)</li>
          <li>Consider smartphone adoption rates in India</li>
        </ul>

        <h3>4. Define Upper and Lower Bounds</h3>
        <p>
          Setting upper and lower limits for your estimates can prevent your answer from spiraling out of control.
        </p>
        <p><strong>Example (India): "How many two-wheelers are sold in India each year?"</strong></p>
        <ul className="bullet-list">
          <li>Lower limit: Close to zero (if two-wheelers weren't popular)</li>
          <li>Upper limit: Total population (~1.4 billion), but unrealistic</li>
          <li>Reasonable estimate: 10-15 million units annually</li>
        </ul>
      </section>

      <section className="strategy-section">
        <h2 className="section-title">Avoiding Math Errors</h2>
        <p>
          Mental math can be tricky under pressure. Here are a few strategies to simplify your calculations during estimation questions:
        </p>

        <h3>1. Round Difficult Numbers</h3>
        <p>
          When dealing with inconvenient numbers, round them to make the math easier.
        </p>
        <ul className="bullet-list">
          <li>1.4 billion can be rounded to 1.4 billion or 1.5 billion</li>
          <li>843 million can be rounded to 800 million or 850 million</li>
        </ul>

        <h3>2. Split Multiplications</h3>
        <p>
          When multiplying large numbers, break the multiplication into easier parts.
        </p>
        <p><strong>Example: 17 x 120</strong></p>
        <ul className="bullet-list">
          <li>Split: 17 x (100 + 20) = (17 x 100) + (17 x 20)</li>
          <li>Calculate: 1700 + 340 = 2040</li>
        </ul>

        <h3>3. Simplify Powers of Ten</h3>
        <p>
          Multiplying or dividing by powers of ten is easy if you move the decimal point over.
        </p>
        <p><strong>Example: 50 x 1,000</strong></p>
        <ul className="bullet-list">
          <li>Add decimal: 50.0</li>
          <li>Move decimal three places right: 50,000</li>
        </ul>
      </section>

      <section className="strategy-section">
        <h2 className="section-title">Increasing the Accuracy of Your Answer</h2>
        <p>
          Accuracy is essential in estimation questions, and there are several strategies to help ensure your answer feels reasonable.
        </p>

        <h3>1. Gut-Check Your Estimates</h3>
        <p>
          Always take a moment to review your estimate and see if it feels right. If something seems off, go back and check your assumptions.
        </p>
        <p><strong>Example:</strong> If you estimated that the average person in India buys 10 smartphones a year, you'd know right away that's unrealistic. You could revisit your assumptions and adjust accordingly.</p>

        <h3>2. Put the Answer in Context</h3>
        <p>
          Sometimes numbers can be hard to interpret, especially if they're very large. Try putting your answer in context to make sense of it.
        </p>
        <p><strong>Example:</strong> If you estimate that India sells 50 million smartphones a year, compare it to the total population of smartphone users (~750 million). This puts your estimate into a meaningful context, as it suggests that roughly 1 in 15 smartphone users buys a new phone annually.</p>

        <h3>3. Check Against Competitors</h3>
        <p>
          Using industry benchmarks or competitor data can help you ensure your estimate is reasonable.
        </p>
        <p><strong>Example:</strong> If asked to estimate the number of orders on Swiggy per day, you could benchmark it against Zomato or other food delivery apps to get a better sense of whether your number seems realistic.</p>
      </section>

      <section className="conclusion-section">
        <h2 className="section-title">Conclusion</h2>
        <p>
          These strategies will help you navigate tricky estimation questions with confidence. By breaking down the problem, using personal references or proxies, and simplifying math, you can arrive at a reasonable answer even when the question seems daunting. Always be ready to check your estimates, make adjustments, and explain your thought process clearly.
        </p>
      </section>

    </div>
  );
};

export default withCourseNavigation(EstimationStrategiesTricks);
