import React from 'react';

const EstimationQuickReference = () => {
  return (
    <div className="course-content">
      <h1 className="course-title">Quick Reference for Estimation Questions (India)</h1>

      <p className="course-intro">
        For PM estimation questions, it's helpful to have a general sense of key numbers and figures. While you don't need to memorize these facts, having an intuition about these figures can help you make reasonable estimates during interviews.
      </p>

      <section className="interview-section">
        <h2 className="section-title">General Population and Size</h2>
        <ul className="bullet-list">
          <li>India population: ~1.4 billion</li>
          <li>Delhi population: ~30 million</li>
          <li>Mumbai population: ~20 million</li>
          <li>Bangalore population: ~12 million</li>
          <li>Rural population percentage: ~65% (around 910 million)</li>
          <li>Urban population percentage: ~35% (around 490 million)</li>
          <li>Total households in India: ~250 million</li>
          <li>Average household size in India: ~4.5 people</li>
          <li>Life expectancy in India: ~70 years</li>
          <li>Size of India: ~3.28 million square km</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">General Sense of Indian Economy</h2>
        <ul className="bullet-list">
          <li>India GDP (2023): ~$3.7 trillion</li>
          <li>Median household income (urban): ~₹2.5 lakh/year</li>
          <li>Median household income (rural): ~₹1 lakh/year</li>
          <li>India's annual two-wheeler sales: ~20 million units</li>
          <li>India's annual smartphone sales: ~150 million units</li>
          <li>India's e-commerce market size (2023): ~$120 billion</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Technical and Digital</h2>
        <ul className="bullet-list">
          <li>Average file size for a 1080p movie (90 minutes): ~2-3GB</li>
          <li>Average internet speed in India: ~100Mbps (urban) / ~25Mbps (rural)</li>
          <li>Cost of iPhone 14 Pro: ~₹1.3 lakh</li>
          <li>Average conversion rate for Indian e-commerce sites: ~1-1.5%</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">User Populations and Technology Usage</h2>
        <ul className="bullet-list">
          <li>Internet users in India: ~750 million</li>
          <li>Smartphone users in India: ~650-700 million</li>
          <li>WhatsApp users in India: ~500 million</li>
          <li>YouTube users in India: ~450 million</li>
          <li>Instagram users in India: ~250 million</li>
          <li>UPI transactions (monthly): ~8 billion</li>
          <li>Zomato active users: ~50 million</li>
          <li>Ola registered drivers: ~2.5 million</li>
          <li>Amazon India product listings: ~160 million</li>
        </ul>
      </section>

      <div className="navigation-buttons">
        <button className="nav-button prev-button">Previous</button>
        <button className="nav-button next-button">Next</button>
      </div>
    </div>
  );
};

export default EstimationQuickReference;