import React from 'react';

const TechnicalInterviewRubric = () => {
  return (
    <div className="course-content">
      <h1 className="course-title">Evualation Criteria of Technical Interviews</h1>

      <p className="course-intro">
        Now that we've covered how to approach technical product questions, let's take a look at how your responses are scored.
      </p>

      <section className="interview-section">
        <h2 className="section-title">Core Skills to Demonstrate in Technical Interviews</h2>
        <p>
          While many junior and mid-level PM roles might not include technical rounds, if you're interviewing for a tech-heavy company or a technical PM role, it's helpful to brush up on the basics of software implementation and technical communication.
        </p>
        <p>
          Technical questions generally assess three core areas:
        </p>
        <ul className="bullet-list">
          <li>Analytical skills</li>
          <li>Critical thinking</li>
          <li>Domain knowledge</li>
        </ul>
        <p>
          These qualities are usually scored on a five-point scale, ranging from "very weak" to "very strong." Each round, interviewers rate your performance and discuss their feedback to decide whether you're a strong fit for the role.
        </p>
        <p>
          Let's dive deeper into what interviewers are looking for in each category.
        </p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Data Literacy</h2>
        <p>
          In technical interviews, questions often involve comparing the performance of different components, architectures, or approaches. You'll need to reason through your answers using data and metrics.
        </p>
        <ul className="bullet-list">
          <li><strong>Very Weak or Missing:</strong> Did not use data to inform the answer.</li>
          <li><strong>Weak:</strong> Missed obvious patterns in the data or didn't focus on key points.</li>
          <li><strong>Neutral:</strong> Demonstrated some data reasoning but overlooked key details.</li>
          <li><strong>Strong:</strong> Used data effectively, asked solid questions, and made logical assumptions.</li>
          <li><strong>Very Strong:</strong> Showed deep insights, spotted patterns, and proposed additional reports or tests to validate the argument.</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Comfort with Metrics</h2>
        <p>
          Metrics are essential in execution interviews, so when a technical question is posed, your ability to choose and interpret the right metrics is critical.
        </p>
        <ul className="bullet-list">
          <li><strong>Very Weak or Missing:</strong> Did not show basic understanding of metrics.</li>
          <li><strong>Weak:</strong> Struggled to define or compare relevant metrics for the given problem.</li>
          <li><strong>Neutral:</strong> Demonstrated fair knowledge but missed out on the "best fit" metrics.</li>
          <li><strong>Strong:</strong> Discussed various metrics thoroughly and made a solid case for their choices.</li>
          <li><strong>Very Strong:</strong> Provided deep insights into metrics and suggested ways to streamline analytical processes.</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Clarifying Questions</h2>
        <p>
          It's easy to jump into answering a technical question, but the best approach is to start by asking clarifying questions. This allows you to properly scope the problem and make sure your answer aligns with what the interviewer is asking.
        </p>
        <ul className="bullet-list">
          <li><strong>Very Weak or Missing:</strong> Failed to ask any clarifying questions.</li>
          <li><strong>Weak:</strong> Asked irrelevant or vague questions, or made assumptions without seeking clarification.</li>
          <li><strong>Neutral:</strong> Asked reasonable questions but missed key points.</li>
          <li><strong>Strong:</strong> Asked insightful questions that guided the answer in the right direction.</li>
          <li><strong>Very Strong:</strong> Asked excellent, surprising questions, and summarized the problem statement clearly.</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Ability to Discuss Trade-offs and Possible Errors</h2>
        <p>
          In any technical discussion, there are always multiple possible solutions. As a PM, you're expected to weigh trade-offs and make decisions based on what's best for the user and the product. This skill shows that you can balance technical constraints with business needs.
        </p>
        <ul className="bullet-list">
          <li><strong>Very Weak or Missing:</strong> Did not discuss trade-offs or errors.</li>
          <li><strong>Weak:</strong> Mentioned trade-offs but couldn't justify decisions or made incorrect judgment calls.</li>
          <li><strong>Neutral:</strong> Discussed trade-offs and errors but could have made better decisions.</li>
          <li><strong>Strong:</strong> Identified trade-offs logically and correctly spotted potential errors.</li>
          <li><strong>Very Strong:</strong> Showed deep understanding of trade-offs, neatly summarizing alternatives and weighing pros and cons.</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Basic Knowledge of Software Implementation</h2>
        <p>
          While you won't be expected to know the ins and outs of coding, having a basic understanding of how software is implemented is essential. Check out your target company's engineering blog for a feel of how they build and roll out software.
        </p>
        <ul className="bullet-list">
          <li><strong>Very Weak or Missing:</strong> Failed to demonstrate any knowledge of software implementation.</li>
          <li><strong>Weak:</strong> Couldn't dive into the details of the implementation process.</li>
          <li><strong>Neutral:</strong> Showed a fair understanding of basic software implementation.</li>
          <li><strong>Strong:</strong> Had a solid grasp of the technical processes behind software.</li>
          <li><strong>Very Strong:</strong> Demonstrated deep, engineering-level knowledge of software processes.</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Technical Communication</h2>
        <p>
          In any technical PM role, you'll frequently interact with engineers. Being able to translate product requirements into technical language and vice versa is key. Your ability to bridge the gap between product teams and engineering will be tested.
        </p>
        <ul className="bullet-list">
          <li><strong>Very Weak or Missing:</strong> Struggled to communicate effectively with technical teams.</li>
          <li><strong>Weak:</strong> Needed clarification on translating PM requirements into technical terms.</li>
          <li><strong>Neutral:</strong> Communicated reasonably well, translating PM needs into technical terms.</li>
          <li><strong>Strong:</strong> Accurately translated product requirements into technical terms and could participate in live technical discussions.</li>
          <li><strong>Very Strong:</strong> Showed a deep understanding of technical language, matching the communication level of engineers.</li>
        </ul>
      </section>

      <div className="navigation-buttons">
        <button className="nav-button prev-button">Previous</button>
        <button className="nav-button next-button">Next</button>
      </div>
    </div>
  );
};

export default TechnicalInterviewRubric;