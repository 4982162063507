import React from 'react';
import withCourseNavigation from '../hoc/withCourseNavigation';

const PMInterviewFramework = () => {
  return (
    <div className="">
      <h1 className="course-title">Mastering the Framework</h1>

      <p className="course-intro">
        A product management interview is more than just answering questions — it's about showcasing how you think, analyze, and communicate. To help you ace it, here's a framework that will guide you through each step of the process. Follow this, and you'll not only impress the interviewer but also feel more confident handling the trickiest questions.
      </p>

       <div className="image-container mb-6">
          <img 
            src="https://i.imgur.com/g02V5Z6.png" 
            alt="Skills for product managers" 
            className="w-full h-auto rounded-lg"
          />
        </div>

      <section className="interview-section">
        <h2 className="section-title">1. Listen and Take Notes</h2>
        <p>
          This might sound basic, but it's often overlooked. The first step in any interview is to actively listen to the question. Don't just hear it, but really listen. As you do, make quick notes of any key points or words that stand out.
        </p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">2. Ask Clarifying Questions</h2>
        <p>
          Once you've heard the question, don't rush into your answer. Take a moment to ask a few clarifying questions. This will not only help you understand the specifics of the problem but also show that you're thoughtful and detail-oriented. A good product manager never assumes—they ask the right questions to get all the information they need. But <b>please don't ask clarifying question just sake for asking - ask only genuine question that will help you in structuring your answer better otherwise you can just repeat the question and confirm if you truly understand the question.</b>
        </p>
        <h3 className="subsection-title">Sample clarifying questions:</h3>
        <ul className="bullet-list">
          <li>"Is the product focused on a specific user group?"</li>
          <li>"Are we talking about a web-based solution or a mobile app?"</li>
          <li>"Is this launch targeted for the Indian market, or is it global?"</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">3. Pause and Think</h2>
        <p>
          Here's a golden rule: Don't rush to answer. Take a deep breath and give yourself a few seconds to organize your thoughts. It might feel like the interviewer is waiting for an immediate response, but they'll appreciate a well-thought-out answer much more than something rushed. A quick pause of 20-30 seconds can significantly improve the quality of your response.
        </p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">4. Structure Your Answer</h2>
        <p>
          Before jumping into your answer, outline a clear structure. This keeps your thoughts organized and ensures your answer flows logically. It also gives the interviewer a sense of where you're going, which helps them follow along.
        </p>
        <p>
          One simple technique is to break your answer into three points. For example, you could start with: "I'm going to discuss three potential approaches to this problem, their trade-offs, and the reasoning behind each." It signals that you're thinking methodically and allows the interviewer to guide you if needed.
        </p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">5. Explain Your Answer</h2>
        <p>
          Now's the time to dive into the details. Walk the interviewer through your thinking step by step. Explain how you approach the problem, what factors you considered, and how you arrived at your solution. Be confident, make eye contact, and use visuals like a whiteboard or paper (if it's offline setting) to help illustrate your thought process. This helps make your answer more engaging and easier to follow.
        </p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">6. Adapt and Check-In</h2>
        <p>
          Sometimes, you might notice the interviewer's body language suggests confusion or disagreement. Don't panic. Simply pause and check in with them. Say something like, "I've covered the first part of my answer; does that align with what you were expecting?"
        </p>
        <p>
          It's also possible that mid-answer, you realize you've made a mistake. Don't worry—it happens! A smooth pivot can save the day. For example: "As I think about this more, I see there's a flaw in my approach. Let me adjust and take it in a slightly different direction."
        </p>
        <p>
          If you blank out or forget your point, just take a moment. It's completely fine to ask for a few more seconds: "I need a moment to think through the next part." If you can't recover, it's better to pivot to the next section than stay stuck.
        </p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">7. Summarize Your Answer</h2>
        <p>
          Before wrapping up, quickly summarize your main points. This step is often overlooked, but it leaves a lasting impression on the interviewer. Keep it concise—just a 30-second recap of what you've discussed.
        </p>
        <p>
          For example: "To summarize, we looked at three approaches: A, B, and C, and I explained how each one addresses the problem differently."
        </p>
        <p>
          This simple closing statement reinforces your structure and leaves a strong final impression.
        </p>
      </section>

      <p className="course-conclusion">
        By following this framework, you'll not only answer questions effectively but also demonstrate the key traits of a great product manager: clear thinking, communication, and adaptability. And remember, it's all about staying calm and confident throughout the interview.
      </p>
    </div>
  );
};

export default withCourseNavigation(PMInterviewFramework);