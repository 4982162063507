// src/components/EstimationQuestions/EstimationStrategies.js
import React from 'react';
import withCourseNavigation from '../hoc/withCourseNavigation';

const EstimationStrategies = () => {
  return (
    <div>
      <div class="course-content">
  <h1 class="course-title">Cracking the Product Manager Interview Code</h1>

  <p class="course-intro">
    Product manager interviews are designed to assess your ability to excel in the core responsibilities of the role - designing innovative products, developing winning strategies, solving complex problems, and making data-driven decisions. While every company has its own flavor of PM interview, most will test you on a common set of skills and question types.
  </p>

  <p class="course-intro">
    To impress your interviewers and land the job, you'll need to showcase your product sense, analytical rigor, technical understanding, leadership ability, and culture fit. Here's a rundown of the most frequently asked PM interview questions and how to approach them:
  </p>

  <section class="interview-section">
    <h2 class="section-title">Product Design Questions</h2>
    <p>
      At the heart of the PM role is the ability to build products that delight users and drive business results. Interviewers will probe your product thinking by asking you to critique an existing product, brainstorm improvements, or design a new product from scratch.
    </p>

    <h3 class="subsection-title">To ace design questions:</h3>
    <ul class="bullet-list">
      <li>Empathize with the target users and articulate their needs and pain points</li>
      <li>Break down the problem and prioritize the most impactful opportunities</li>
      <li>Generate creative solutions and evaluate the tradeoffs</li>
      <li>Determine what metrics you would track to assess success</li>
    </ul>

    <h3 class="subsection-title">Sample design questions:</h3>
    <ul class="bullet-list">
      <li>How would you improve the Uber Eats delivery experience for restaurants?</li>
      <li>Design a mobile app to help new parents manage their baby's sleep schedule</li>
      <li>If TikTok wanted to add a feature for content creators to monetize, what would you suggest?</li>
    </ul>
  </section>

  <section class="interview-section">
    <h2 class="section-title">Strategy Questions</h2>
    <p>
      PMs need to ensure their products support overarching business objectives. Interviewers will present case-style questions to test your ability to analyze market opportunities, develop go-to-market plans, set pricing, and outmaneuver competitors.
    </p>

    <h3 class="subsection-title">To tackle strategy questions:</h3>
    <ul class="bullet-list">
      <li>Clarify the objective and identify the target market and key stakeholders</li>
      <li>Assess the competitive landscape and the company's unique value prop</li>
      <li>Evaluate different strategic options and make a recommendation</li>
      <li>Explain how you would measure success and mitigate risks</li>
    </ul>

    <h3 class="subsection-title">Sample strategy questions:</h3>
    <ul class="bullet-list">
      <li>Should Netflix get into the live sports streaming market? How would you decide?</li>
      <li>Imagine you're the PM for Lyft's new luxury ride service. How do you price it?</li>
      <li>If Apple wanted to enter the smart home market, what product would you recommend they launch first and why?</li>
    </ul>
  </section>

  <div class="navigation-buttons">
    <button class="nav-button prev-button" disabled>Previous</button>
    <button class="nav-button next-button">Next</button>
  </div>
</div>
      {/* Add more content here */}
      <button style={{
        backgroundColor: '#6c63ff',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginTop: '20px'
      }}>
        Next
      </button>
    </div>
  );
};

export default withCourseNavigation(EstimationStrategies);
