// src/components/hoc/withCourseNavigation.js

import React, { useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import FeedbackForm from './FeedbackForm';
import { UserContext } from '../../UserContext';

const courseStructure = [
  // Introduction
  { path: '/dashboard/intro/welcome', next: '/dashboard/intro/resume-optimisation' },
  { path: '/dashboard/intro/resume-optimisation', next: '/dashboard/intro/job-application-strategy' },
  { path: '/dashboard/intro/job-application-strategy', next: '/dashboard/intro/question-types' },
  { path: '/dashboard/intro/question-types', next: '/dashboard/intro/framework', prev: '/dashboard/intro/welcome' },
  { path: '/dashboard/intro/framework', next: '/dashboard/intro/glossary', prev: '/dashboard/intro/question-types' },
  { path: '/dashboard/intro/grading', next: '/dashboard/intro/glossary', prev: '/dashboard/intro/framework' },
  { path: '/dashboard/intro/glossary', next: '/dashboard/analytical/intro', prev: '/dashboard/intro/framework' },

  // Analytical Questions
  { path: '/dashboard/analytical/intro', next: '/dashboard/analytical/ab-testing', prev: '/dashboard/intro/glossary' },
  { path: '/dashboard/analytical/ab-testing', next: '/dashboard/analytical/metrics', prev: '/dashboard/analytical/intro' },
  { path: '/dashboard/analytical/metrics', next: '/dashboard/analytical/rubric', prev: '/dashboard/analytical/ab-testing' },
  { path: '/dashboard/analytical/rubric', next: '/dashboard/analytical/practice', prev: '/dashboard/analytical/metrics' },
  { path: '/dashboard/analytical/practice', next: '/dashboard/execution/intro', prev: '/dashboard/analytical/rubric' },

  // Execution Questions
  { path: '/dashboard/execution/intro', next: '/dashboard/execution/root-cause', prev: '/dashboard/analytical/practice' },
  { path: '/dashboard/execution/root-cause', next: '/dashboard/execution/decision-making', prev: '/dashboard/execution/intro' },
  { path: '/dashboard/execution/decision-making', next: '/dashboard/execution/rubric', prev: '/dashboard/execution/root-cause' },
  { path: '/dashboard/execution/rubric', next: '/dashboard/execution/practice', prev: '/dashboard/execution/decision-making' },
  { path: '/dashboard/execution/practice', next: '/dashboard/product-design/intro', prev: '/dashboard/execution/rubric' },

  // Product Design Questions
  { path: '/dashboard/product-design/intro', next: '/dashboard/product-design/answering', prev: '/dashboard/execution/practice' },
  { path: '/dashboard/product-design/answering', next: '/dashboard/product-design/brainstorming', prev: '/dashboard/product-design/intro' },
  { path: '/dashboard/product-design/brainstorming', next: '/dashboard/product-design/vision', prev: '/dashboard/product-design/answering' },
  { path: '/dashboard/product-design/vision', next: '/dashboard/product-design/rubric', prev: '/dashboard/product-design/brainstorming' },
  { path: '/dashboard/product-design/rubric', next: '/dashboard/product-design/practice', prev: '/dashboard/product-design/vision' },
  { path: '/dashboard/product-design/practice', next: '/dashboard/product-strategy/intro', prev: '/dashboard/product-design/rubric' },

  // Product Strategy Questions
  { path: '/dashboard/product-strategy/intro', next: '/dashboard/product-strategy/answering', prev: '/dashboard/product-design/practice' },
  { path: '/dashboard/product-strategy/answering', next: '/dashboard/product-strategy/go-to-market', prev: '/dashboard/product-strategy/intro' },
  { path: '/dashboard/product-strategy/go-to-market', next: '/dashboard/product-strategy/pricing', prev: '/dashboard/product-strategy/answering' },
  { path: '/dashboard/product-strategy/pricing', next: '/dashboard/product-strategy/growth', prev: '/dashboard/product-strategy/go-to-market' },
  { path: '/dashboard/product-strategy/growth', next: '/dashboard/product-strategy/rubric', prev: '/dashboard/product-strategy/pricing' },
  { path: '/dashboard/product-strategy/rubric', next: '/dashboard/product-strategy/practice', prev: '/dashboard/product-strategy/growth' },
  { path: '/dashboard/product-strategy/practice', next: '/dashboard/behavioral/mastering', prev: '/dashboard/product-strategy/rubric' },

   // Estimation Questions
  { path: '/dashboard/estimation/overview', next: '/dashboard/estimation/answering', prev: '/dashboard/intro/glossary' },
  { path: '/dashboard/estimation/answering', next: '/dashboard/estimation/strategies', prev: '/dashboard/estimation/overview' },
  { path: '/dashboard/estimation/strategies', next: '/dashboard/estimation/reference', prev: '/dashboard/estimation/answering' },
  { path: '/dashboard/estimation/reference', next: '/dashboard/estimation/practice', prev: '/dashboard/estimation/strategies' },
  { path: '/dashboard/estimation/practice', next: '/dashboard/analytical/intro', prev: '/dashboard/estimation/reference' },

  // Behavioral & Technical Interviews
  { path: '/dashboard/behavioral/mastering', next: '/dashboard/behavioral/story-bank', prev: '/dashboard/product-strategy/practice' },
  { path: '/dashboard/behavioral/story-bank', next: '/dashboard/technical/intro', prev: '/dashboard/behavioral/mastering' },
  { path: '/dashboard/technical/intro', next: '/dashboard/technical/tackling', prev: '/dashboard/behavioral/story-bank' },
  { path: '/dashboard/technical/tackling', next: '/dashboard/technical/evaluating', prev: '/dashboard/technical/intro' },
  { path: '/dashboard/technical/evaluating', next: '/dashboard/technical/practice', prev: '/dashboard/technical/tackling' },
  { path: '/dashboard/technical/practice', prev: '/dashboard/technical/evaluating' }
];

const withCourseNavigation = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const currentPage = courseStructure.find(page => page.path === location.pathname);

    const { user } = useContext(UserContext); // for example, if you have a user context
    const userEmail = user?.email || 'anonymous@example.com';

    useEffect(() => {
      // Reset scroll position when the route changes
      window.scrollTo(0, 0);
    }, [location.pathname]);

    const goToNext = () => {
      if (currentPage && currentPage.next) {
        navigate(currentPage.next);
      }
    };

    const goToPrevious = () => {
      if (currentPage && currentPage.prev) {
        navigate(currentPage.prev);
      }
    };

    return (
      <div className="course-content">
        <WrappedComponent {...props} />
        <FeedbackForm userEmail={userEmail} />
        <div className="navigation-buttons">
          {/* Removed the Previous button 
          <button 
            onClick={goToPrevious} 
            className="nav-button prev-button"
            disabled={!currentPage || !currentPage.prev}
          >
            Previous
          </button> */}
          <button 
            onClick={goToNext} 
            className="nav-button next-button"
            disabled={!currentPage || !currentPage.next}
          >
            Next
          </button>
        </div>
      </div>
    );
  };
};

export default withCourseNavigation;