import React from 'react';
import withCourseNavigation from '../hoc/withCourseNavigation';

const GrowthStrategyGuide = () => {
  return (
    <div className="course-content">
      <h1 className="course-title">How to Answer Growth Strategy Questions</h1>

      <p className="course-intro">
        Growth strategy questions test your ability to identify the key levers that will drive a company's or product's growth. Common examples include:
      </p>
      <ul className="bullet-list">
        <li>"How would you increase the number of Instagram users?"</li>
        <li>"How would you 10X usage of a product like Jiocinema?"</li>
        <li>"How would you boost in-app purchases by 20% for a mobile gaming app?"</li>
      </ul>

      <p className="course-intro">
        There are many different approaches to growth, and the path you choose depends on the product's current stage and the market landscape. You might focus on acquiring new users, driving more engagement from existing users, or reducing churn to retain customers. Your approach should align with the company's goals and user behavior insights.
      </p>

      <p className="course-intro">
        Growth questions can be tackled with the standard strategy framework, but with a greater focus on where the company is now and how to leverage user behavior to drive growth.
      </p>

      <section className="interview-section">
        <h2 className="section-title">Step 1: Define the Landscape</h2>
        <p>
          Begin by clarifying the problem and gathering enough context to model the goal with a simple equation.
        </p>
        <p>
          For instance, if you were asked to reduce churn by 10%, you'd start with the basic churn formula:
        </p>
        <blockquote className="example-quote">
          Churn rate = (lost customers / total customers at the beginning of the period) x 100
        </blockquote>
        <p>
          To reduce churn, you could either decrease the number of lost customers or increase total customers, keeping losses steady. Confirm this understanding with your interviewer, and clarify any ambiguity in the question.
        </p>
        <p>
          Ask questions that will help you understand:
        </p>
        <ul className="bullet-list">
          <li>The key value drivers of the product or business.</li>
          <li>Any existing growth strategies, the metrics being tracked, and how they've performed over time.</li>
          <li>Specific strengths and weaknesses in the product or business that might impact growth.</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Step 2: Identify Growth Options</h2>
        <p>
          Once you have a clear understanding of the product and market, it's time to identify the right growth strategy. The most common approaches include:
        </p>
        <ul className="bullet-list">
          <li><strong>Market Penetration:</strong> Focus on increasing market share in your existing market, often by attracting new users, adjusting pricing, or increasing marketing efforts.</li>
          <li><strong>Market Expansion:</strong> Expand into new markets by targeting new customer segments or geographical areas with your existing product.</li>
          <li><strong>Product Development:</strong> Create new products or features to engage your existing market further.</li>
          <li><strong>Diversification:</strong> Enter a new market with a new product—this is typically the riskiest but could bring significant growth if executed well.</li>
        </ul>
        <p>
          As you develop your strategy, remember to consider the company's position in the market and its mission. For example, a startup may take a bolder, riskier approach than a large, established company. Growth strategies should also align with user behavior—understanding what motivates your users can help you identify new growth opportunities.
        </p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Step 3: Prioritize and Justify Your Growth Plan</h2>
        <p>
          Now that you have several potential strategies in mind, it's time to prioritize the one that best aligns with the company's strengths and market position. Ask yourself:
        </p>
        <ul className="bullet-list">
          <li>What's the likelihood of success for each option?</li>
          <li>What's the expected impact of each?</li>
          <li>Are there any long-term strategic benefits?</li>
        </ul>
        <p>
          Once you've chosen the best option, justify it and explain why it's the most effective strategy for driving growth. Be prepared to evaluate your answer by considering:
        </p>
        <ul className="bullet-list">
          <li>Trade-offs you've made.</li>
          <li>Risks you anticipate and how you would mitigate them.</li>
          <li>Metrics you would track to measure the success of your strategy.</li>
        </ul>
      </section>

      <section className="interview-section mb-8">
        <h2 className="section-title text-2xl font-bold mb-4">Growth Example: How would you increase Instagram Reels' average engagement rate by 2X over the next 3 years?</h2>

        <div className="step-explanation mb-6">
          <h3 className="subsection-title text-xl font-semibold mb-3">Step 1: Clarify the Problem</h3>
          <p className="mb-4">
            Begin by clarifying what "engagement rate" means:
          </p>
          <ul className="bullet-list list-disc pl-6 mb-4">
            <li>"Are we focused on likes, comments, shares, or all forms of interaction?"</li>
            <li>"Does this target all regions or specific markets where engagement is low?"</li>
          </ul>
          <p className="mb-4">
            Assume the interviewer confirms the focus is on all forms of engagement (likes, comments, shares, saves) for global markets.
          </p>
          <p className="mb-4">
            Now, break down the goal into actionable components:
          </p>
          <p className="mb-4">
            Engagement Rate = (Total Engagements ÷ Total Impressions) x 100
          </p>
          <blockquote className="example-quote italic border-l-4 border-gray-300 pl-4 mb-4">
            "To double the engagement rate, we could increase the total number of likes, comments, shares, and saves per Reel, or reduce the denominator (total impressions) by making the content more targeted to users' interests, or ideally, both."
          </blockquote>
          <p className="mb-4">
            This gives you a clear framework for your strategies.
          </p>
        </div>

        <div className="step-explanation mb-6">
          <h3 className="subsection-title text-xl font-semibold mb-3">Step 2: Define the Landscape</h3>
          <p className="mb-4">
            Understand Instagram Reels' current position in the market:
          </p>
          <p className="mb-4">
            <strong>Strengths:</strong>
          </p>
          <blockquote className="example-quote italic border-l-4 border-gray-300 pl-4 mb-4">
            "Instagram Reels has a strong base with seamless integration into Instagram's ecosystem and a global reach. It benefits from existing creators, influencers, and brands."
          </blockquote>
          <p className="mb-4">
            <strong>Challenges:</strong>
          </p>
          <blockquote className="example-quote italic border-l-4 border-gray-300 pl-4 mb-4">
            "Reels competes directly with TikTok, which is a leader in short-form content engagement. Instagram users also associate the platform more with photos and stories than short-form videos, which limits Reels' perception as a go-to feature for quick entertainment."
          </blockquote>
          <p className="mb-4">
            By identifying where Reels excels and where it faces challenges, you can create more targeted growth strategies.
          </p>
        </div>

        <div className="step-explanation mb-6">
          <h3 className="subsection-title text-xl font-semibold mb-3">Step 3: Generate Growth Ideas</h3>
          <p className="mb-4">
            With the goal of doubling Reels' engagement rate, consider strategies across three dimensions:
          </p>

          <div className="mb-4">
            <h4 className="font-semibold mb-2">1. Increase User Engagement:</h4>
            <p className="mb-4">
              <strong>Algorithm Personalization:</strong>
            </p>
            <blockquote className="example-quote italic border-l-4 border-gray-300 pl-4 mb-4">
              "Improve the recommendation algorithm to deliver more hyper-relevant Reels to users based on their behavior (e.g., time spent on a type of content, previous likes). A better match between user interests and content will naturally increase likes, comments, and shares."
            </blockquote>
            <p className="mb-4">
              <strong>Interactive Features:</strong>
            </p>
            <blockquote className="example-quote italic border-l-4 border-gray-300 pl-4 mb-4">
              "Introduce features like live reactions, emoji-based quick responses, and real-time comments during Reels playback. This encourages immediate engagement."
            </blockquote>
            <p className="mb-4">
              <strong>Gamify Engagement:</strong>
            </p>
            <blockquote className="example-quote italic border-l-4 border-gray-300 pl-4 mb-4">
              "Launch badges or rewards for users who actively engage with Reels. For instance, introduce a 'Top Fan' badge for users who consistently like, comment, or share content from their favorite creators."
            </blockquote>
          </div>

          <div className="mb-4">
            <h4 className="font-semibold mb-2">2. Empower Creators:</h4>
            <p className="mb-4">
              <strong>Creator Tools:</strong>
            </p>
            <blockquote className="example-quote italic border-l-4 border-gray-300 pl-4 mb-4">
              "Provide advanced editing tools, AI-powered templates, and trending audio recommendations to help creators produce high-quality, engaging content effortlessly."
            </blockquote>
            <p className="mb-4">
              <strong>Monetization Incentives:</strong>
            </p>
            <blockquote className="example-quote italic border-l-4 border-gray-300 pl-4 mb-4">
              "Offer engagement-based payouts for creators (e.g., bonuses for Reels with high likes or shares). This incentivizes creators to focus on producing engaging content."
            </blockquote>
            <p className="mb-4">
              <strong>Collaborations:</strong>
            </p>
            <blockquote className="example-quote italic border-l-4 border-gray-300 pl-4 mb-4">
              "Encourage collaborations between creators and brands to produce high-quality content that resonates with users and drives engagement."
            </blockquote>
          </div>

          <div className="mb-4">
            <h4 className="font-semibold mb-2">3. Expand User Base:</h4>
            <p className="mb-4">
              <strong>Localized Content:</strong>
            </p>
            <blockquote className="example-quote italic border-l-4 border-gray-300 pl-4 mb-4">
              "Promote Reels in under-penetrated markets (e.g., emerging regions in Africa or Southeast Asia) by encouraging creators to produce content in regional languages and focusing on culturally relevant themes."
            </blockquote>
            <p className="mb-4">
              <strong>Cross-Platform Integration:</strong>
            </p>
            <blockquote className="example-quote italic border-l-4 border-gray-300 pl-4 mb-4">
              "Enable Reels to be seamlessly shared to other platforms (e.g., Facebook, WhatsApp) to bring in users who are not actively using Instagram."
            </blockquote>
          </div>

          <div className="mb-4">
            <h4 className="font-semibold mb-2">4. Special Features for High-Value Engagement:</h4>
            <p className="mb-4">
              <strong>Polls and Quizzes:</strong>
            </p>
            <blockquote className="example-quote italic border-l-4 border-gray-300 pl-4 mb-4">
              "Introduce polls, quizzes, or 'this-or-that' questions embedded in Reels to spark conversations and shares."
            </blockquote>
            <p className="mb-4">
              <strong>Story-Like Features in Reels:</strong>
            </p>
            <blockquote className="example-quote italic border-l-4 border-gray-300 pl-4 mb-4">
              "Add the ability for creators to embed clickable links or 'swipe up' options in their Reels, making them more interactive and engaging."
            </blockquote>
          </div>
        </div>

        <div className="step-explanation mb-6">
          <h3 className="subsection-title text-xl font-semibold mb-3">Step 4: Prioritize the Best Strategy</h3>
          <p className="mb-4">
            Let's say you decide to focus on algorithm personalization and empowering creators as your top priorities:
          </p>
          <p className="mb-4">
            <strong>Why Algorithm Personalization?</strong>
          </p>
          <blockquote className="example-quote italic border-l-4 border-gray-300 pl-4 mb-4">
            "Hyper-relevant content ensures users spend more time on the platform and are more likely to engage. TikTok's success stems from its ability to surface the right content to the right audience, and Instagram Reels can replicate this with its rich user data."
          </blockquote>
          <p className="mb-4">
            <strong>Why Empower Creators?</strong>
          </p>
          <blockquote className="example-quote italic border-l-4 border-gray-300 pl-4 mb-4">
            "Creators drive content quality, which directly impacts engagement. By providing better tools and financial incentives, Instagram can motivate creators to prioritize Reels, making them more engaging and attractive to users."
          </blockquote>
        </div>

        <div className="step-explanation mb-6">
          <h3 className="subsection-title text-xl font-semibold mb-3">Step 5: Evaluate and Recap</h3>
          <p className="mb-4">
            Wrap up by evaluating the chosen strategies:
          </p>
          <p className="mb-4">
            <strong>Short-Term Wins:</strong>
          </p>
          <ul className="bullet-list list-disc pl-6 mb-4">
            <li>Improved recommendation algorithms can show measurable results in a matter of months by surfacing highly engaging Reels to users.</li>
            <li>Creator monetization programs can boost content quality and drive more frequent posting.</li>
          </ul>
          <p className="mb-4">
            <strong>Long-Term Vision:</strong>
          </p>
          <blockquote className="example-quote italic border-l-4 border-gray-300 pl-4 mb-4">
            "Building a loyal base of creators and delivering hyper-personalized content ensures sustainable growth in engagement. As Reels' ecosystem matures, Instagram could dominate the short-form video space by offering both a superior user experience and better opportunities for creators."
          </blockquote>
          <p className="mb-4">
            <strong>Potential Risks and Mitigation:</strong>
          </p>
          <ul className="bullet-list list-disc pl-6 mb-4">
            <li>
              <strong>Risk:</strong> Over-reliance on algorithmic improvements could lead to echo chambers where users see repetitive content.
              <br />
              <strong>Mitigation:</strong> Introduce a "Discover" mode to diversify content exposure.
            </li>
            <li>
              <strong>Risk:</strong> Creators might find monetization incentives less competitive compared to TikTok or YouTube.
              <br />
              <strong>Mitigation:</strong> Regularly benchmark and adjust payouts to remain competitive.
            </li>
          </ul>
          <p className="mb-4">
            <strong>Metrics to Track:</strong>
          </p>
          <ul className="bullet-list list-disc pl-6 mb-4">
            <li>Engagement rate (likes, comments, shares, saves ÷ impressions)</li>
            <li>Average time spent per user on Reels</li>
            <li>Growth in creator participation and uploads</li>
          </ul>
        </div>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Common Pitfalls</h2>
        <ul className="bullet-list">
          <li><strong>Don't skip context:</strong> Always begin by clarifying the problem and defining the landscape. Without context, your solution might not be practical.</li>
          <li><strong>Consider the user journey:</strong> Whether you're acquiring new users or increasing engagement, think about how users interact with the product and where there are opportunities for growth.</li>
          <li><strong>Weigh trade-offs:</strong> Many growth strategies come with trade-offs—consider the impact of prioritizing short-term gains over long-term growth, or vice versa.</li>
        </ul>
      </section>
    </div>
  );
};

export default withCourseNavigation(GrowthStrategyGuide);
