import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { supabase } from '../../supabaseClient'; // make sure the path is correct
import { UserContext } from '../../UserContext';


const FeedbackForm = ({ userEmail }) => {
  const location = useLocation();
  const pageUrl = location.pathname;
  
  const [rating, setRating] = useState(null); // 'up' or 'down'
  const [feedbackText, setFeedbackText] = useState('');
  const [showTextArea, setShowTextArea] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleThumbsUp = () => {
    setRating('up');
    setShowTextArea(true);
  };

  const handleThumbsDown = () => {
    setRating('down');
    setShowTextArea(true);
  };

  const handleSubmit = async () => {
    setError(null);
    try {
      const { data, error } = await supabase.from('feedback').insert([
        {
          user_email: userEmail,
          page_url: pageUrl,
          rating,
          feedback_text: feedbackText
        }
      ]);
      if (error) throw error;
      setSubmitSuccess(true);
    } catch (err) {
      console.error(err);
      setError('Something went wrong. Please try again.');
    }
  };

  // Reset form if user changes pages
  useEffect(() => {
    setRating(null);
    setFeedbackText('');
    setShowTextArea(false);
    setSubmitSuccess(false);
    setError(null);
  }, [pageUrl]);

  if (submitSuccess) {
    return <p>Thanks for your feedback!</p>;
  }

  return (
    <div className="mt-8 p-4 border-t border-gray-300">
  {/* Title */}
  <div className="mb-4 text-lg font-semibold">Was this helpful?</div>
  
  {/* Feedback Buttons */}
  <div className="flex items-center gap-4 mb-4">
    <button
      onClick={handleThumbsUp}
      disabled={rating === 'up'}
      className={`p-2 rounded-lg border ${
        rating === 'up'
          ? 'bg-blue-500 text-white border-blue-500'
          : 'bg-white text-gray-600 border-gray-300 hover:bg-blue-100'
      }`}
    >
      👍
    </button>
    <button
      onClick={handleThumbsDown}
      disabled={rating === 'down'}
      className={`p-2 rounded-lg border ${
        rating === 'down'
          ? 'bg-blue-500 text-white border-blue-500'
          : 'bg-white text-gray-600 border-gray-300 hover:bg-blue-100'
      }`}
    >
      👎
    </button>
  </div>

  {/* Text Area */}
  {showTextArea && (
    <div className="mb-4">
      <textarea
        placeholder="What did you like or dislike?"
        value={feedbackText}
        onChange={(e) => setFeedbackText(e.target.value)}
        rows={2}
        className="w-3/4 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400 md:w-full"
      />
    </div>
  )}

  {/* Submit Button */}
  {showTextArea && (
    <button
      onClick={handleSubmit}
      disabled={!rating}
      className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
    >
      Submit
    </button>
  )}

  {/* Error Message */}
  {error && <p className="mt-2 text-red-500">{error}</p>}
</div>

  );
};

export default FeedbackForm;
