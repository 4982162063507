// src/components/Introduction/Welcome.js
import React from 'react';
import withCourseNavigation from '../hoc/withCourseNavigation';

const PMMasterySeries = () => {
  return (
    <div className="">
      <h1 className="course-title">Welcome to the PM Mastery Series!</h1>

      <p className="course-intro">
        We have created this series with one and only one goal: to help you become better at interview preparation. During our initial years in Product Management, we clearly realized that while you may excel at your day job as a Product Manager, you might lack the skills required to crack the interview. Yes, cracking an interview is a skill set in itself. This series will be your go-to guide for mastering the art of PM interviews, with a focus on problems and questions from Indian startups and companies.
      </p>

      <section className="interview-section">
        <h2 className="section-title">What have we covered in this PM Mastery Series?</h2>
        <p>
          We have covered almost all types of questions that are asked in PM interviews today. However, interviewers also know that there is plenty of content available across the web, so interviews evolve over time. Here is a list of a few key elements we’ve included:
        </p>
        <ul className="bullet-list">
          <li>Practice questions covering all major PM interview topics (product design/sense, strategy, estimation, analytics, and more).</li>
          <li>An interactive and self-paced format, allowing you to learn at your own speed (you can even complete this in a week).</li>
          <li>Guides and frameworks tailored to tackle each type of PM interview question.</li>
          <li>WhatsApp community to help you get feedback and ideas</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">What Skills Do Companies Look for in Product Managers?</h2>
        <p>
          Before we go further into the types of interview questions you'll face, it's important to understand why they're asked in the first place. If you are already a product manager, you might have seen debates around statements like "PMs are the CEOs of the product," etc. While we don’t want to agree or disagree with this statement, we will simply highlight some of the core skills required to excel in a PM role.
        </p>
        <p>
          Let’s face it—the role of a product manager is broad, and companies need to ensure you are equipped with the right mix of skills to handle the job well. Most companies look for some common traits in addition to specific experience, which we will discuss later in this series. For now, let’s first look at some common traits:
        </p>
        <ul className="bullet-list">
          <li><strong>User Empathy:</strong> Can you think like a user and prioritize their needs?</li>
          <li><strong>Business Sense:</strong> Do you understand how your product impacts the company's growth and revenue?</li>
          <li><strong>Clear Communication:</strong> Are you able to collaborate with cross-functional teams and manage stakeholders effectively?</li>
          <li><strong>Leadership Without Authority:</strong> Can you take ownership and inspire teams, even without direct control?</li>
          <li><strong>Problem-Solving Skills:</strong> Are you good at breaking down complex problems and finding practical solutions?</li>
          <li><strong>Strategic Planning:</strong> Can you create a strong product roadmap and adapt to market shifts?</li>
          <li><strong>Analytical Thinking:</strong> Are you data-driven, using metrics and experimentation to make informed decisions?</li>
          <li><strong>Getting Things Done:</strong> Do you have the ability to manage projects and ensure smooth execution?</li>
        </ul>
        <div className="image-container mb-6">
          <img 
            src="https://i.imgur.com/ZrD7RXB.png" 
            alt="Skills for product managers" 
            className="w-full h-auto rounded-lg"
          />
        </div>
        <p>
          While this might seem like a lot, mastering these skills is essential for thriving as a PM. Each question you'll encounter in an interview is designed to test one or more of these areas, so it's important to be ready to showcase your strengths.
        </p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">How to Get the Most Out of This Series?</h2>
        <p>Mostly PM interview questions usually fall into a few main categories:</p>
        <ul className="bullet-list">
          <li><strong>Product Design/Sense:</strong> These questions challenge your creativity and understanding of what makes a product successful.</li>
          <li><strong>Product Strategy:</strong> Focus on business decisions and long-term vision in product development.</li>
          <li><strong>Analytics & Execution:</strong> You'll need to think critically about metrics, data, and overall strategy.</li>
          <li><strong>Estimation:</strong> Here, you'll show your ability to estimate things on the fly with limited data. It's mostly asked if you are interviewing for APM roles.</li>
          <li><strong>Technical:</strong> These questions test your ability to collaborate effectively with engineers.</li>
        </ul>
        <p>
          We have started all the modules with short intro that will help you understand the questions better.
        </p>
        <p className="pro-tip">
          A key tip: Don't just read the answers. Take the time to practice and then, compare your answer to the guide. Remember, there's no "perfect" answer to most PM interview questions, but our answers will help you shape your own structure and improve.
        </p>
        <p>
          Once you've gotten the hang of it, aim to tackle couple of questions everyday. This way, you'll cover a variety of topics over a month. We also have a question bank with a timer for practice question. <a href="https://theproducthope.com/product-manager-practice-interview-questions/" target="_blank" rel="noreferrer">Check this out here.</a>
        </p>
      </section>


      <section className="interview-section">
        <h2 className="section-title">Stay Connected</h2>
        <p>
          If you have any questions, don't hesitate to reach out in our WhatsApp community for help. There's always someone around to share insights and offer guidance.
        </p>
      </section>
    </div>
  );
};

export default withCourseNavigation(PMMasterySeries);