// src/components/Introduction/Resume.js
import React from 'react';
import withCourseNavigation from '../hoc/withCourseNavigation';

const ResumeOptimisation = () => {
  return (
    <div className="">
      <h1 className="course-title">Optimizing Your Resume for PM Roles</h1>

      <p className="course-intro">
        Let's first start with fixing your resume. Whether you're targeting early-stage PM roles or mid-senior positions, this guide will help you improve your resume to reflect your career achievements, align with recruiter expectations, and stand out in a crowded market. Please feel free to skip this module if you already have a strong resume.
      </p>

      <section className="interview-section">
        <h2 className="section-title">Why Your Resume Matters</h2>
        <p>
          With recruiters spending less than 10–20 seconds reviewing each resume, your resume needs to deliver maximum impact quickly and efficiently.
        </p>
        <p>
          Consider your resume as a summary of your career impact. Recruiters mainly try to identify:
        </p>
        <ul className="bullet-list">
          <li>Are you relevant to this role?</li>
          <li>What have you achieved in the past?</li>
          <li>What do you bring to the table?</li>
        </ul>
        <p> Here is sample resume template: </p>
      </section>

      <div className="image-container mb-6">
          <a href="https://docs.google.com/document/d/1e_PV05fK_f7sf91aOyZR87Ip0qLu5SZ2tR8Qvzouf9E/edit?usp=sharing" target="_blank"> <img 
            src="https://i.imgur.com/gWEQUFL.png" 
            alt="Skills for product managers" 
            className="w-2/3 h-auto rounded-lg border border-gray-300"
          />
          </a> 
      </div>

      <section className="interview-section">
        <h2 className="section-title">Key Elements of a PM Resume</h2>
        <h3 className="text-xl font-semibold mt-4">Resume Formatting</h3>
        <ul className="bullet-list">
          <li><strong>Stick to One Page:</strong> For early to mid-career roles, keep your resume concise. If you are a Senior PM with 8+ years of experience, you may extend it to two pages.</li>
          <li><strong>Use Readable Fonts:</strong> Opt for professional fonts like Arial or Calibri in size 11–12.</li>
          <li><strong>Keep It Consistent:</strong> Ensure uniform spacing, font sizes, and formatting throughout.</li>
          <li><strong>Optimize for Skim-Reading:</strong> Use bullet points, subheadings, and white space.</li>
        </ul>

        <h4 className="text-lg font-medium mt-4">Avoid:</h4>
        <ul className="bullet-list">
          <li>Over-designing: Fancy layouts can confuse ATS systems.</li>
          <li>Generic Information: Tailor your resume for each job application or at least create one resume for each type of role, like Growth PM or Tech PM, if you feel you qualify for both.</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Detailed Resume Sections</h2>
        
        <div className="mb-6">
          <h3 className="text-xl font-semibold">1. Header</h3>
          <p>The header is the topmost section of your resume and arguably the most important piece. It needs to provide your basic information in a clear, professional manner.</p>
          
          <h4 className="text-lg font-medium mt-4">What to Include:</h4>
          <ul className="bullet-list">
            <li><strong>Name:</strong> Use a larger font to make it prominent.</li>
            <li><strong>Contact Information:</strong> Phone number and a professional email address.</li>
            <li><strong>LinkedIn Profile:</strong> Ensure your LinkedIn is updated and aligned with your resume.</li>
            <li><strong>Portfolio Link (if applicable):</strong> Include it if you've built a product portfolio or personal website showcasing case studies or teardowns.</li>
          </ul>
          
          <p className="pro-tip mt-4">Pro Tip: Avoid including your detailed address, photo, etc.</p>
        </div>

        <div className="mb-6">
          <h3 className="text-xl font-semibold">2. The Professional Summary</h3>
          <p>A well-crafted summary can set the tone for the entire resume. Think of it as your elevator pitch: keep it concise, highlight key relevant skills, and showcase some impact.</p>
          
          <h4 className="text-lg font-medium mt-4">Structure of a Strong Summary:</h4>
          <ul className="bullet-list">
            <li><strong>Length:</strong> 3–4 sentences.</li>
            <li><strong>Focus:</strong> Highlight your years of experience, key skills, and measurable achievements.</li>
            <li><strong>Keywords:</strong> Align your wording with the job description (e.g., product strategy, user insights).</li>
          </ul>
          
          <div className="bg-gray-100 p-4 rounded-lg mt-4">
            <h4 className="font-medium">Example:</h4>
            <p className="italic">"Product Manager with 5+ years of experience launching SaaS products and driving $2M ARR growth. Skilled in product strategy and customer research, with a proven track record of reducing customer churn by [xx%] through UX design improvements."</p>
          </div>
        </div>
      </section>

            <section className="interview-section">
        <h2 className="section-title">3. Work Experience</h2>
        <p>This section is the heart of your resume, where you showcase your accomplishments, not just your responsibilities.</p>
        
        <h3 className="text-xl font-semibold mt-4">How to Structure Work Experience:</h3>
        <ul className="bullet-list">
          <li><strong>Reverse Chronological Order:</strong> Start with your most recent role.</li>
          <li><strong>Action-Impact Format:</strong> Use action verbs and quantify your impact.</li>
          <li><strong>The SAR Framework:</strong> Structure each bullet as Situation, Action, and Result.</li>
        </ul>

        <div className="bg-gray-100 p-4 rounded-lg mt-4">
          <h4 className="font-medium">Example:</h4>
          <p className="italic">"Led a team of 4 engineers to deliver a mobile app feature, resulting in a 25% increase in daily active users."</p>
        </div>

        <h4 className="text-lg font-medium mt-4">Some No-Brainers:</h4>
        <ul className="bullet-list">
          <li>Emphasize strategic contributions (e.g., roadmap development, stakeholder management).</li>
          <li>Showcase measurable outcomes (e.g., increased revenue, cost savings, reduced time-to-market) in the form of percentages.</li>
        </ul>

        <h4 className="text-lg font-medium mt-4">What to Avoid:</h4>
        <ul className="bullet-list">
          <li><strong>Exaggeration:</strong> Overstating your impact. If you did something great, be honest about it.</li>
          <li><strong>Buzzwords:</strong> Avoid filler phrases like "team player" unless substantiated by results.</li>
        </ul>

        <h4 className="text-lg font-medium mt-4">Examples to Learn From:</h4>
        <ul className="bullet-list">
          <li>Launched X new features, driving a Y% boost in user engagement.</li>
          <li>Improved Y feature's UX, resulting in Z% ARPU at a base of $XXM ARR.</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">4. Skills Section</h2>
        <p>The skills section ensures you pass ATS (Applicant Tracking System) filters while highlighting your competencies.</p>
        
        <h3 className="text-xl font-semibold mt-4">Tips for a Great Skills Section:</h3>
        <ul className="bullet-list">
          <li><strong>Customize:</strong> Use keywords from the job description.</li>
          <li><strong>Include Tools:</strong> Mention relevant tools (e.g., Jira, SQL, Tableau).</li>
          <li><strong>Combine Core Competencies:</strong> Show a mix of technical skills, PM frameworks, and interpersonal abilities.</li>
        </ul>

        <div className="bg-gray-100 p-4 rounded-lg mt-4">
          <h4 className="font-medium">Example:</h4>
          <p>
            <strong>Core Competencies:</strong> Product Strategy, Roadmap Planning, A/B Testing or Experimentation<br />
            <strong>Technical Skills:</strong> SQL, Wireframing, Prototyping, Python
          </p>
        </div>
      </section>

      <section className="interview-section">
        <h2 className="section-title">5. Education and Extracurricular Activities</h2>
        <h3 className="text-xl font-semibold mt-4">Education</h3>
        <ul className="bullet-list">
          <li>Highlight your degree and any relevant coursework.</li>
          <li>For mid-senior roles, you can skip grades or CGPA unless it's exceptional and recent.</li>
          <li>Mention relevant certifications or bootcamps.</li>
        </ul>

        <h3 className="text-xl font-semibold mt-4">Extracurricular Activities</h3>
        <p>Especially useful for early-career PMs or career switchers. It shows your initiative and leadership outside the workplace.</p>
        
        <ul className="bullet-list">
          <li><strong>Side Businesses:</strong> Highlight user or revenue growth.</li>
          <li><strong>Coding Projects:</strong> Show that you understand tech basics.</li>
          <li><strong>Community Involvement:</strong> Mention PM-related meetups, hackathons, or volunteer roles.</li>
          <li><strong>Hobbies:</strong> Add a personal touch, but keep it professional (e.g., "Runner and marathon organizer").</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Actionable Tips to Enhance Your Resume</h2>
        <ul className="bullet-list">
          <li><strong>Use Metrics Wherever Possible:</strong> Numbers tell a compelling story.
            <ul className="pl-4 list-disc">
              <li>"Reduced user churn by 10% through personalized recommendations."</li>
              <li>"Increased DAU by 20% by launching new features based on user insights."</li>
            </ul>
          </li>
          <li><strong>Tailor for Every Job Application:</strong> Customize keywords and skills to align with the job description.</li>
          <li><strong>Proofread Thoroughly:</strong> Typos and inconsistencies can detract from your credibility. Use tools like Grammarly.</li>
          <li><strong>Explain Lesser-Known Companies:</strong> Add a brief description for organizations that might not be widely recognized.</li>
          <li><strong>Avoid Overcrowding:</strong> Stick to 3–5 bullet points per job to maintain clarity and impact.</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Common Mistakes to Avoid</h2>
        <ul className="bullet-list">
          <li>Overloading with Irrelevant Information: Keep it concise and job-focused.</li>
          <li>Neglecting ATS Optimization: Ensure your resume includes role-relevant keywords.</li>
          <li>Failing to Quantify Impact: Vague statements lack credibility.</li>
        </ul>
      </section>

      <p className="pro-tip mt-6">
        Remember, your resume is your professional story. Make every word count and showcase your unique value as a Product Manager.
      </p>
    </div>
  );
};

export default withCourseNavigation(ResumeOptimisation);



