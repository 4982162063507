import React from 'react';
import withCourseNavigation from '../hoc/withCourseNavigation';

const PMInterviewGlossary = () => {
  const glossaryTerms = [
  {
    "term": "A/B Test",
    "definition": "A method for comparing two versions of a webpage, app or flow to see which performs better.",
    "example": "Testing two different homepages on Swiggy to determine which one leads to more orders."
  },
  {
    "term": "API (Application Programming Interface)",
    "definition": "A tool that allows one application to interact with another.",
    "example": "When you log in to BookMyShow using your Facebook credentials, that's an API at work."
  },
  {
    "term": "ARPU (Average Revenue Per User)",
    "definition": "This metric shows the average revenue generated per user.",
    "example": "Netflix calculates ARPU by dividing its total revenue by the number of subscribers."
  },
  {
    "term": "Beta Test",
    "definition": "The phase in which a product is released to a small group of users for testing before the full launch.",
    "example": "Paytm might release a new feature to a few thousand users to test for bugs."
  },
  {
    "term": "Bounce Rate",
    "definition": "The percentage of visitors who leave a website after viewing only one page. A high bounce rate may suggest that users are not finding what they are looking for quickly enough.",
    "example": ""
  },
  {
    "term": "Burn Rate",
    "definition": "The rate at which a company is spending its cash reserves. Startups monitor burn rate to ensure they don't run out of funding before becoming profitable.",
    "example": ""
  },
  {
    "term": "CAC (Customer Acquisition Cost)",
    "definition": "The amount spent to acquire a new customer.",
    "example": "If Zomato spends ₹500 on ads to get one new customer, that's the CAC."
  },
  {
    "term": "Churn Rate",
    "definition": "The percentage of users who stop using a product over a given period.",
    "example": "Reducing churn is crucial for subscription-based businesses like Hotstar."
  },
  {
    "term": "Conversion Rate",
    "definition": "The percentage of users who complete a desired action, such as making a purchase or signing up for a service.",
    "example": "If 10 out of 100 users on the Nykaa app make a purchase, the conversion rate is 10%."
  },
  {
    "term": "CPC (Cost Per Click)",
    "definition": "The amount advertisers pay for each click on their ad.",
    "example": "If an advertiser on Flipkart pays ₹10 for every click on their ad, that's the CPC."
  },
  {
    "term": "CPM (Cost Per Thousand Impressions)",
    "definition": "The amount advertisers pay per 1,000 impressions (views) of their ad.",
    "example": "A campaign on Instagram might charge ₹200 per 1,000 views of an ad."
  },
  {
    "term": "CTR (Click-Through Rate)",
    "definition": "The percentage of people who click on a link or ad.",
    "example": "If 100 people see an ad on YouTube and 5 people click on it, the CTR is 5%."
  },
  {
    "term": "Customer Segment",
    "definition": "A group of users with shared characteristics that a product or marketing campaign is tailored for.",
    "example": "Myntra might segment customers by \"frequent shoppers\" and \"occasional buyers.\""
  },
  {
    "term": "DAU/MAU (Daily Active Users/Monthly Active Users)",
    "definition": "Metrics used to measure user engagement. DAUs track daily usage, while MAUs track monthly activity.",
    "example": "Flipkart uses DAUs to see how many people are using its app each day."
  },
  {
    "term": "Dogfooding",
    "definition": "When a company uses its own product internally before launching it to the public.",
    "example": "The Google Maps team testing a new feature internally before releasing it to users."
  },
  {
    "term": "Early Adopters",
    "definition": "The first group of users to try a product.",
    "example": "When Cred launched its rewards feature, its early adopters provided valuable feedback."
  },
  {
    "term": "Go-to-Market Strategy",
    "definition": "The blueprint for launching a product, covering pricing, marketing, and distribution.",
    "example": "When PhonePe launched UPI payments, its strategy included partnerships with merchants and promotional offers for users."
  },
  {
    "term": "GMV (Gross Merchandise Value)",
    "definition": "The total sales value of goods sold via an online marketplace.",
    "example": "GMV for Amazon India is the combined value of all products sold on the platform."
  },
  {
    "term": "Impression",
    "definition": "The number of times an ad or content is displayed to users.",
    "example": "If an Uber Eats ad is shown 10,000 times in a day, that's 10,000 impressions."
  },
  {
    "term": "KPI (Key Performance Indicator)",
    "definition": "A measurable value that shows how effectively a company or product is achieving its goals.",
    "example": "A KPI for a video streaming service like Hotstar could be the number of hours watched per user."
  },
  {
    "term": "Low-Hanging Fruit",
    "definition": "An easy task or quick win that can bring significant improvement to a product.",
    "example": "Adding a \"one-click checkout\" button on Flipkart could be low-hanging fruit for improving conversion rates."
  },
  {
    "term": "MVP (Minimum Viable Product)",
    "definition": "The simplest version of a product that delivers core functionality.",
    "example": "UrbanClap's initial MVP might have been a simple platform connecting service providers with users, without any advanced features."
  },
  {
    "term": "North Star Metric",
    "definition": "The single most important metric that best represents the success of a product.",
    "example": "For Zomato, the north star metric might be the number of food orders per day."
  },
  {
    "term": "Pain Point",
    "definition": "A specific problem faced by users that the product aims to solve.",
    "example": "A pain point in India's online grocery delivery space could be unreliable delivery times, which apps like BigBasket address."
  },
  {
    "term": "Product Roadmap",
    "definition": "A strategic plan that outlines the vision and direction of a product over time.",
    "example": "Swiggy's roadmap might include launching drone deliveries or expanding into rural markets."
  },
  {
    "term": "Refactor",
    "definition": "Rewriting or reorganizing code to improve performance or reduce complexity without changing the product's functionality.",
    "example": "Refactoring Paytm's backend code to handle more users without slowing down the app."
  },
  {
    "term": "ROI (Return on Investment)",
    "definition": "A measure of the profitability of an investment.",
    "example": "If you spend ₹1 lakh on a marketing campaign and it generates ₹5 lakh in revenue, your ROI is 5x."
  },
  {
    "term": "UI (User Interface)",
    "definition": "The visual part of a product that users interact with.",
    "example": "A clean and intuitive UI is one of the reasons why Google Pay has been so successful."
  },
  {
    "term": "Use Case",
    "definition": "A specific scenario describing how a user interacts with a product.",
    "example": "A use case for Zomato could be a user booking a table at a restaurant through the app."
  },
  {
    "term": "UX (User Experience)",
    "definition": "The overall experience a user has with a product.",
    "example": "Ola's seamless booking and ride tracking provide a smooth user experience, keeping customers happy."
  },
  {
    "term": "Value Proposition",
    "definition": "The unique value that a product offers to its users.",
    "example": "Cred's value proposition is turning credit card bill payments into a rewarding experience by offering exclusive perks."
  }
];

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="course-title">PM Interview Glossary</h1>

      <p className="text-lg mb-8">
        Here's a comprehensive list of common terms used by product managers, organized alphabetically. This glossary includes key product management concepts as well as essential growth metrics, complete with examples from Indian startups to help illustrate the concepts.
      </p>

      <div className="space-y-8">
        {glossaryTerms.map((item, index) => (
          <div key={index} className="border-b border-gray-200 pb-6 last:border-b-0">
            <h2 className="text-xl font-semibold text-indigo-600 mb-2">{item.term}</h2>
            <p className="mb-2">{item.definition}</p>
            {item.example && (
              <p className="text-sm text-gray-600 italic">
                <strong>Example:</strong> {item.example}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default withCourseNavigation(PMInterviewGlossary);