import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu, User } from 'lucide-react';
import { supabase } from '../../supabaseClient';

const Header = ({ user, onLogout, toggleSidebar }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [userInitials, setUserInitials] = useState('');

  useEffect(() => {
    if (user) {
      fetchUserProfile();
    }
  }, [user]);

  const fetchUserProfile = async () => {
    try {
      const { data, error } = await supabase
        .from('user_profiles')
        .select('name')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;

      if (data && data.name) {
        setUserInitials(getInitials(data.name));
      }
    } catch (error) {
      console.error('Error fetching user profile:', error.message);
    }
  };

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <header className="bg-white shadow-sm fixed top-0 left-0 right-0 z-50">
      <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center h-16">
        <div className="flex items-center">
          <button
            className="mr-2 md:hidden"
            onClick={toggleSidebar}
          >
            <Menu size={24} />
          </button>
          <Link to="/" className="flex items-center">
            <img 
              src="https://i.imgur.com/RYoSbJ9.png" 
              alt="Product Hope Logo" 
              className="h-8 w-auto"
            />
          </Link>
        </div>
        <nav className="flex items-center">
          {user ? (
            <div className="relative">
              <button
                onClick={toggleDropdown}
                className="w-8 h-8 rounded-full bg-indigo-100 flex items-center justify-center text-indigo-600 font-semibold"
              >
                {userInitials || user.email[0].toUpperCase()}
              </button>
              
              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                  <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    {/* <Link
                      to="/profile"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left flex items-center"
                      role="menuitem"
                      onClick={() => setIsDropdownOpen(false)}
                    >
                      <User size={16} className="mr-2" />
                      Profile
                    </Link> */}
                    <button
                      onClick={() => {
                        onLogout();
                        setIsDropdownOpen(false);
                      }}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                      role="menuitem"
                    >
                      Logout
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <>
              {/* <Link to="/login" className="text-sm text-gray-700 hover:text-gray-900 mr-4">Login</Link>
              <Link to="/signup" className="text-sm text-indigo-600 hover:text-indigo-900">Sign up</Link> */}
            </>
          )}
        </nav>
      </div>
    </header>
  );
};

export default Header;