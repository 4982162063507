import React from 'react';

const ProductStrategyInterviewRubric = () => {
  return (
    <div className="course-content">
      <h1 className="course-title">Evaluation criteria for Product Strategy Interviews</h1>

      <p className="course-intro">
        Now that we've covered how to answer product strategy questions, let's talk about how they are typically scored. Product strategy interviews are designed to assess your product skills, critical thinking, and culture fit. Companies usually score candidates on a five-point scale, from "very weak" to "very strong," for each core quality. During hiring discussions, interviewers share their feedback and scores based on this rubric.
      </p>

      <p className="course-intro">
        Let's break down the key areas interviewers focus on and how they rate your answers:
      </p>

      <section className="interview-section">
        <h2 className="section-title">1. Competitive Analysis</h2>
        <p>
          When thinking strategically, you're never operating in isolation. It's important to understand your competitors and the market trends that might affect your product's success. Failing to mention competitors or trends is a major red flag in product strategy interviews.
        </p>
        <ul className="bullet-list">
          <li><strong>Very Weak or Missing:</strong> Ignored competitors altogether.</li>
          <li><strong>Weak:</strong> Showed limited understanding of the competitive landscape.</li>
          <li><strong>Neutral:</strong> Basic awareness of key competitors and market trends.</li>
          <li><strong>Strong:</strong> Demonstrated good insight into competitors and their market positioning.</li>
          <li><strong>Very Strong:</strong> Showed deep understanding of the competitive landscape, identified opportunities, and discussed competitive strategies creatively.</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">2. Market Analysis</h2>
        <p>
          While competitive analysis is important, you also need to grasp broader market dynamics—things like consumer behavior trends, supply chain impacts, and economic factors. Your ability to connect these factors to your strategy will be key.
        </p>
        <ul className="bullet-list">
          <li><strong>Very Weak or Missing:</strong> Failed to consider the broader market context.</li>
          <li><strong>Weak:</strong> Poor understanding of market forces.</li>
          <li><strong>Neutral:</strong> Fair understanding of the key market drivers and participants.</li>
          <li><strong>Strong:</strong> Demonstrated strong knowledge of market trends and dynamics.</li>
          <li><strong>Very Strong:</strong> Delivered insightful and creative market analysis, identifying opportunities and challenges clearly.</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">3. Business Strategy</h2>
        <p>
          Here's where you show you've connected your strategy to the company's goals and its position in the market. You need to think about how your product strategy fits into the bigger picture, taking into account the company's overall objectives and strengths.
        </p>
        <ul className="bullet-list">
          <li><strong>Very Weak or Missing:</strong> Didn't consider business goals or basic strategy.</li>
          <li><strong>Weak:</strong> Strategy was unclear or had major errors.</li>
          <li><strong>Neutral:</strong> Articulated a reasonable strategy with some guidance.</li>
          <li><strong>Strong:</strong> Delivered a solid strategy that aligned with business goals.</li>
          <li><strong>Very Strong:</strong> Displayed nuanced strategic thinking, tying everything back to business goals with compelling reasoning.</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">4. Roadmapping</h2>
        <p>
          Once you have a strategy, it's crucial to create a realistic and adaptable roadmap that will help the company execute the plan. This is a critical part of any product manager's role, so interviewers pay attention to how well you can plan and communicate your vision.
        </p>
        <ul className="bullet-list">
          <li><strong>Very Weak or Missing:</strong> Didn't present a roadmap or had significant issues.</li>
          <li><strong>Weak:</strong> Roadmap was unclear or flawed.</li>
          <li><strong>Neutral:</strong> Presented a basic roadmap with some guidance.</li>
          <li><strong>Strong:</strong> Roadmap fit the competitive landscape and business goals well, with clear assumptions and alternatives.</li>
          <li><strong>Very Strong:</strong> Provided a clear, compelling roadmap that was adaptable and well thought out.</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">5. Clarifying Questions</h2>
        <p>
          Product strategy questions are about problem-solving, and the key to solving any problem is understanding it fully. Asking clarifying questions demonstrates that you can identify what information is missing and avoid making risky assumptions.
        </p>
        <ul className="bullet-list">
          <li><strong>Very Weak or Missing:</strong> Failed to ask clarifying questions.</li>
          <li><strong>Weak:</strong> Asked a few questions but missed key points.</li>
          <li><strong>Neutral:</strong> Asked good questions but could have gone deeper.</li>
          <li><strong>Strong:</strong> Asked insightful questions that helped refine the strategy.</li>
          <li><strong>Very Strong:</strong> Scoped the problem effectively, leading to a high-quality strategy.</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">6. Ability to Discuss Tradeoffs and Errors</h2>
        <p>
          Strategic decisions are rarely black and white. You need to weigh pros and cons, consider tradeoffs, and anticipate potential errors or risks. This demonstrates your ability to think critically and make balanced decisions.
        </p>
        <ul className="bullet-list">
          <li><strong>Very Weak or Missing:</strong> Didn't mention tradeoffs or potential errors.</li>
          <li><strong>Weak:</strong> Mentioned tradeoffs but failed to justify decisions well.</li>
          <li><strong>Neutral:</strong> Covered tradeoffs and errors but could have made stronger choices.</li>
          <li><strong>Strong:</strong> Presented logical tradeoffs and identified possible risks.</li>
          <li><strong>Very Strong:</strong> Delivered a deep, intuitive understanding of tradeoffs, summarized alternatives with clear pros and cons.</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">7. Passion and Creativity</h2>
        <p>
          This is where your culture fit shines through. Your passion for the product and the company should come across naturally, and your creativity will help make your solution stand out. Don't be afraid to show excitement!
        </p>
        <ul className="bullet-list">
          <li><strong>Very Weak or Missing:</strong> Lacked enthusiasm or creative ideas.</li>
          <li><strong>Weak:</strong> Showed little interest in the problem or solutions were too bland.</li>
          <li><strong>Neutral:</strong> Displayed interest, but nothing exceptional.</li>
          <li><strong>Strong:</strong> Showed enthusiasm, knowledge, and creativity.</li>
          <li><strong>Very Strong:</strong> Gave inspired answers, demonstrated clear passion for the product and problem.</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">8. Communication</h2>
        <p>
          Clear and proactive communication is essential in every product interview. Interviewers expect you to articulate your thought process, explain your decisions clearly, and engage in a two-way conversation.
        </p>
        <ul className="bullet-list">
          <li><strong>Very Weak or Missing:</strong> Communication was unclear or difficult to follow.</li>
          <li><strong>Weak:</strong> Struggled to communicate ideas despite prompts.</li>
          <li><strong>Neutral:</strong> Communication was clear in some areas but vague in others.</li>
          <li><strong>Strong:</strong> Communicated well, explained ideas clearly and consistently.</li>
          <li><strong>Very Strong:</strong> Displayed proactive communication, checked in with the interviewer, and explained decisions with clarity.</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">9. Collaboration</h2>
        <p>
          Product strategy interviews often turn into collaborative problem-solving exercises. Use this opportunity to involve the interviewer, ask for feedback, and check your assumptions.
        </p>
        <ul className="bullet-list">
          <li><strong>Very Weak or Missing:</strong> Failed to lead or respond to guidance.</li>
          <li><strong>Weak:</strong> Needed guidance to stay on track.</li>
          <li><strong>Neutral:</strong> Took the lead and performed well, but occasionally needed help.</li>
          <li><strong>Strong:</strong> Led the discussion effectively and involved the interviewer.</li>
          <li><strong>Very Strong:</strong> Led the discussion and made excellent use of the interviewer's input, turning the interview into a collaborative effort.</li>
        </ul>
      </section>

      <div className="navigation-buttons">
        <button className="nav-button prev-button">Previous</button>
        <button className="nav-button next-button">Next</button>
      </div>
    </div>
  );
};

export default ProductStrategyInterviewRubric;