import React from 'react';
import withCourseNavigation from '../hoc/withCourseNavigation';

const JobApplicationStrategies = () => {
  return (
    <div className="">
      <h1 className="course-title">Getting Shortlisted in a Competitive Market</h1>

      <p className="course-intro">
        The job market in 2024 - 25 is more competitive than ever, with thousands of candidates vying for a single position. Platforms like LinkedIn have adapted to this reality - highlighting "over 100 applicants" instead of exact numbers like "1800 applicants" to soften the blow for job seekers. But the truth remains: it's crowded, and standing out is tougher than ever.
      </p>

      <div className="image-container mb-6">
          <img 
            src="https://i.imgur.com/bJFw43S.png" 
            alt="Skills for product managers" 
            className="w-1/2 h-auto rounded-lg border border-gray-300"
          />
      </div>

      <p className="course-intro">
        The traditional strategies of cold reach-outs, asking for referrals, or mass applying to jobs are no longer enough. To truly stand out, you need a targeted, thoughtful approach to job applications. This module focuses on how to apply strategically and maximize your chances of getting shortlisted.
      </p>

      <div className="image-container mb-6">
          <img 
            src="https://i.imgur.com/cplb9Dg.png" 
            alt="Skills for product managers" 
            className="w-2/3 h-auto rounded-lg"
          />
      </div>

      <section className="interview-section">
        <h2 className="section-title">The Problem with Mass Applying</h2>
        <p>If you're applying to every single job you come across, you're doing it wrong. Here's why: </p>
        <ul className="bullet-list">
          <li>Lack of Relevance: Not all jobs align with your skills, experience, or aspirations.</li>
          <li>Minimal Impact: Mass applications lack customization and show minimal commitment, which recruiters can sense.</li>
          <li>Recruiter Fatigue: Hiring managers receive hundreds or thousands of applications. If you don't stand out, you're just another resume in the pile.</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">What You Should Do Instead</h2>
        <p>Rather than casting a wide net, focus on targeted and strategic applications. This approach not only increases your chances of getting shortlisted but also ensures you're applying to roles that truly align with your strengths and aspirations.</p>

        <h3 className="section-subtitle">1. Understand Your Strengths</h3>
        <p>Start by evaluating your strengths in terms of domain expertise and functional skills. Recruiters are increasingly looking for candidates with niche expertise that aligns with their role and company needs.</p>

        <p><strong>Domain Expertise:</strong></p>
        <ul className="bullet-list">
          <li>If you have prior experience in marketing, content creation, or running campaigns, target Growth PM roles focused on user acquisition, engagement, and retention.</li>
          <li>For candidates with a background in B2B, SaaS, or enterprise technology, look for Tech PM roles in companies like BrowserStack, Postman, or cloud-based businesses. These domains value problem solvers with an understanding of technical ecosystems.</li>
        </ul>

        <p><strong>Functional Skills:</strong></p>
        <ul className="bullet-list">
          <li>Are you great at analytics, market research, or storytelling? Apply to strategy-focused roles or PM positions in data-heavy environments.</li>
        </ul>

        <p className="pro-tip">Key Insight: It's not about limiting yourself but about focusing your energy on roles where your past experience gives you a strong advantage.</p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">2. Research and Shortlist Jobs</h2>
        <p>Before applying, take the time to thoroughly analyze each job description. Ask yourself:</p>
        <ul className="bullet-list">
          <li>Do I meet the non-negotiables? For example, required years of experience, technical skills, or specific industry expertise.</li>
          <li>Do my strengths align? Map your skills to the key responsibilities listed in the job description.</li>
          <li>Can I offer leverage? Identify areas where your past achievements can directly add value to the role or organization.</li>
        </ul>

        <p className="pro-tip">Pro Tip: Focus on roles where you tick most of the boxes. These are the positions where your chances of getting shortlisted are significantly higher.</p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">3. Invest Time Where It Matters</h2>
        <p>Applying to jobs is not a race—it's about quality, not quantity. Here's why you should spend more time on fewer applications:</p>
        <ul className="bullet-list">
          <li>Customization Shows Commitment: Recruiters can spot a tailored application, and it makes a stronger impression.</li>
          <li>It Reflects Seriousness: A thoughtful approach signals that you're genuinely interested in the role and company.</li>
        </ul>

        <p>What This Looks Like in Practice:</p>
        <p>Instead of sending out 30 generic applications in one day, spend 30 mins to 1 hour carefully tailoring your resume and cover letter for 5–10 roles where you have a strong fit.</p>
        <p>Align your achievements and skills to the job requirements, and use keywords from the job description to make it ATS-friendly.</p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">4. Think Niche and Strategic</h2>
        <p>The job market is becoming more niche than ever, with recruiters valuing candidates who bring relevant expertise.</p>

        <h3 className="section-subtitle">Find Your Niche</h3>
        <p>Think about where your background fits best. For example:</p>
        <ul className="bullet-list">
          <li>If you've worked in data-driven roles, target PM positions requiring strong analytics skills.</li>
          <li>If you have technical experience, focus on Tech PM roles in companies that value engineering alignment.</li>
        </ul>

        <h3 className="section-subtitle">Be Strategic About Company Selection</h3>
        <p>Choose companies that match your skills and experience.</p>
        <p>Example: If you have a background in APIs or developer tools, companies like Postman or BrowserStack may value your expertise.</p>

        <p className="pro-tip">Key Insight: This doesn't mean you can only apply to niche roles, but dedicating more effort to these increases your chances of success.</p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Step-by-Step Guide to Applying Strategically</h2>

        <h3 className="section-subtitle">1. Understand the Recruiter's Perspective</h3>
        <p>Once you have shortlisted the jobs, think from the angle of Recruiters. Hiring managers are overwhelmed by the volume of applications. They want candidates who show:</p>
        <ul className="bullet-list">
          <li>Commitment: A thoughtful, customized application demonstrates effort and interest.</li>
          <li>Clarity: Your resume and cover letter should clearly articulate why you're a fit.</li>
        </ul>

        <p className="pro-tip">User Empathy Tip: Think of the recruiter as your "user." Make their job easier by highlighting your relevance upfront.</p>

        <h3 className="section-subtitle">2. Tailor Your Application</h3>
        <p>Each job is unique, so your application should reflect that.</p>

        <h4>Customize Your Resume:</h4>
        <ul className="bullet-list">
          <li>Duplicate your resume and align key achievements with the job requirements.</li>
          <li>Use keywords from the job description to pass ATS (Applicant Tracking Systems) filters.</li>
          <li>Quantify your impact (e.g., "Increased user retention by 20% through feature enhancements").</li>
        </ul>

        <h4>Write a Cover Letter (If Optional):</h4>
        <ul className="bullet-list">
          <li>Keep it concise (4–5 lines).</li>
          <li>Highlight why you're the right fit and what excites you about the role.</li>
        </ul>

        <p className="pro-tip">Pro Tip: A tailored application shows genuine interest, which stands out in a sea of generic submissions.</p>

        <h3 className="section-subtitle">3. Apply Thoughtfully</h3>
        <ul className="bullet-list">
          <li>Submit it through the official job portal or LinkedIn.</li>
          <li>Double-check for errors or inconsistencies before hitting "Apply."</li>
        </ul>

        <p className="pro-tip">Actionable Tip: If the job post allows additional documents, upload a portfolio or proof of work to show your fitment for the role.</p>
      </section>
            <section className="interview-section">
        <h2 className="section-title">Consider Doing More Things</h2>
        <p>In case you are still not getting shortlist via your customised application, consider few more things</p>

        <h3 className="section-subtitle">1. Reach Out to the Hiring Manager</h3>
        <p>Use LinkedIn InMail to message the recruiter or hiring manager.</p>
        <p>Write a personalized note explaining:</p>
        <ul className="bullet-list">
          <li>Why you're interested in the role.</li>
          <li>How your skills align with their needs.</li>
          <li>Make sure you do your homework well while reaching out to them.</li>
        </ul>

        <p>Example:</p>
        <p className="pro-tip"> "Hi [Name], I've been following all the news about [company] in last few weeks, including Series B funding and crossing [XX] revenue. I admire the work your team is doing in [specific area], and I'm excited about the opportunity to contribute as a Product Manager. My experience in [specific skills/achievements] aligns closely with the role's requirements. I'd love to discuss how I can add value to your team. Thanks!"</p>
        <p>In case, you don't get the response try following up in 2 - 3 days. There is lot more to cold-reachouts, we will create a separate module for this.</p>

        <h3 className="section-subtitle">2. Ask for Referrals the Right Way</h3>
        <p>Referrals are still valuable, but they're super crowded as well. We were speaking to one of the Senior PMs within our Product Hope Community, he mentioned most of the folks are getting around 6 - 7 LinkedIn messages everyday asking for referrals.</p>

        <p>Here's how to do it better:</p>
        <ul className="bullet-list">
          <li>Identify people who work at the company, ideally in the PM team or related departments.</li>
          <li>Send a personalized connection request with a note expressing your interest in their role or the company.</li>
          <li>Share your interest in their company and explain why you're a good fit.</li>
          <li>Offer value: Suggest ways to improve their product or share feedback to make your request more meaningful.</li>
        </ul>

        <h4>Additional Steps</h4>
        <ul className="bullet-list">
          <li>Be Patient: Not everyone will respond immediately. Give it time and follow up politely after a week if you don't hear back.</li>
          <li>Show Gratitude: Always thank people for their time and insights, even if they don't provide a referral.</li>
          <li>Be Genuine: Avoid being transactional. Focus on building relationships, as these connections can help you in the long term.</li>
        </ul>

        <h3 className="section-subtitle">Why This Approach Works</h3>
        <p>Yes, asking for referrals and building connections are high-effort tasks, but nothing can beat this strategy when done thoughtfully. The problem, however, lies in how most applicants approach referrals—they tend to follow a checklist rather than truly understanding the company or product.</p>

        <p>Instead of just checking the box of "asking for a referral," spend time exploring the product, its features, and why the company is doing certain things in a particular way. This not only helps you build a deeper understanding of the company's culture and vision but also sharpens your product sense along the way.</p>

        <p>In fact, even after you get shortlisted for a role, you'll need to take this step. During interviews, showing genuine curiosity by asking thoughtful questions about the company's business model and product decisions will leave a strong impression on hiring managers. It signals that you're not just interested in the job but in the company's mission and growth.</p>

        <p>Referrals aren't just about getting noticed—they're about demonstrating interest, curiosity, and alignment with the company's values. This approach doesn't just get your foot in the door; it sets the stage for meaningful conversations that can differentiate you as a candidate.</p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Common Mistakes to Avoid</h2>
        <ul className="bullet-list">
          <li><strong>Mass Applying:</strong> Stop applying to every job you see. Focus on relevance and quality.</li>
          <li><strong>Generic Applications:</strong> A resume or cover letter that isn't tailored to the job won't stand out.</li>
          <li><strong>No Follow-Up:</strong> Don't just apply and wait. Take proactive steps to connect with hiring managers.</li>
          <li><strong>Lack of Research:</strong> Understand the company and role before applying.</li>
        </ul>
      </section>
    </div>
  );
};

export default withCourseNavigation(JobApplicationStrategies);