import React from 'react';
import withCourseNavigation from '../hoc/withCourseNavigation';

const ABTestingQuestionsPage = () => {
  return (
    <div className="">
      <h1 className="course-title">How to Answer A/B Testing Questions</h1>

      <p className="course-intro">
        A/B testing is a fundamental tool for product managers to understand user behavior and make informed, data-driven decisions. Especially at large tech companies like Hotstar, Paytm, or Flipkart, product managers frequently run experiments to validate product ideas and optimize performance. This is why A/B testing questions are common in PM interviews.
      </p>
      <div className="image-container mb-6">
          <img 
            src="https://i.imgur.com/1EyAz4C.png" 
            alt="Skills for product managers" 
            className="w-full h-auto rounded-lg"
          />
      </div>
      <section className="interview-section">
        <h3 className="subsection-title">1. Objective</h3>
        <p>
          The first step is understanding the objective behind running the A/B test. What problem are you solving, and why does it matter? This sets the direction for your experiment and ensures it aligns with broader business goals. In the context of an interview, this is the stage where you want to ensure you understand the problem or goal clearly. Restate the problem to confirm you fully comprehend the question.
        </p>
        <p><strong>Example Objective:</strong> You’re a PM at Hotstar, and the goal is to increase the <strong>watch time</strong> of users who have just finished watching a movie. You want to keep them engaged with the platform longer by exploring new content.</p>

        <h3 className="subsection-title">2. Observations</h3>
        <p>
          Before diving into the experiment, you need to look at the existing data points—these <strong>observations</strong> help you shape the hypothesis. Observations are insights you gather from current user behavior or analytics. You can ask clarifying questions to build your observations or take some assumptions at this stage - For eg - "How's is current trend of users look like who finished watching a content? Do they drop-off from the platform or explore other shows?"
        </p>
        <p><strong>Example Observation:</strong> "Currently, users who finish a movie tend to drop off the platform or spend time browsing for their next watch, leading to lower overall watch time." This observation could come from analytics or you can take an assumption stating that let's assume that 60% of users leave the platform within five minutes of finishing a movie.</p>

        <h3 className="subsection-title">3. Hypothesis</h3>
        <p>
          Now that you have your objective and observations, it’s time to form a <strong>hypothesis</strong>. This is where you define what change you're testing and why you believe it will work. This is where you clearly highlight the interviewer what's your hypothesis.
        </p>
        <p><strong>Example Hypothesis:</strong> "By introducing auto-playing a relevenat movie or show immediately (with delay of 5 secs) after a user finishes watching a movie, can increase the average watch time by X% thereby increasing overall user retention"</p>

        <h3 className="subsection-title">4. Experiment</h3>
        <p>
          Next, outline the <strong>experiment</strong> you will run to test your hypothesis. This includes the variations, control groups, and any audience segments you're targeting. Be specific about how you plan to run the test.
        </p>
        <p><strong>Example Experiment:</strong> "We will create two user groups. Control group will experience the current setup where nothing happens after a movie ends, and users have to manually browse for the next movie. Group B (the test group) will see an auto-play of a related movie or show immediately after the movie ends. Both groups will see the same recommendations and interface, except for the auto-play feature."</p>

        <h3 className="subsection-title">5. Success Metrics</h3>
        <p>
          In this step, you define what metrics will tell you if the experiment is successful. This includes the <strong>key metrics</strong> that measure the primary goal (e.g., watch time) and the <strong>expected impact</strong> these changes will have on user behavior.
        </p>
        <p><strong>Example Success Metrics:</strong> Key metrics for this Hotstar experiment could include:
          <ul className="bullet-list">
            <li>Average watch time per user (primary success metric)</li>
            <li>Percentage of users who continue to watch the next movie or show</li>
            <li>Drop-off rate before and after auto-play starts</li>
            <li>Number of users who disable auto-play</li>
          </ul>
        </p>
        <p><strong>Expected Impact:</strong> "We expect a 10-15% increase in average watch time for users in Group B, along with a reduction in drop-offs post-movie completion."</p>

        <h3 className="subsection-title">6. Guardrail Metrics</h3>
        <p>
          It’s important to keep an eye on <strong>guardrail metrics</strong>—these help ensure the experiment doesn’t unintentionally harm other aspects of the user experience or business.
        </p>
        <p><strong>Example Guardrail Metrics:</strong>
          <ul className="bullet-list">
            <li>User satisfaction scores: If auto-play causes frustration, satisfaction scores may drop.</li>
            <li>Retention rate: A decrease in retention might indicate that users are annoyed by the auto-play feature and leave the platform more quickly.</li>
          </ul>
        </p>
      </section>

      <div class="container mx-auto p-4">
        <h2 class="text-2xl font-bold mb-4">A/B Testing Template</h2>

        <table class="table-auto w-full border-collapse border border-gray-300">
          <thead>
            <tr class="bg-gray-100">
              <th class="border px-4 py-2 text-left">Section</th>
              <th class="border px-4 py-2 text-left">Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border px-4 py-2 font-bold">Objective</td>
              <td class="border px-4 py-2">
                Clearly define the core user or business problem that the experiment aims to solve.
              </td>
            </tr>
            <tr>
              <td class="border px-4 py-2 font-bold">Observations</td>
              <td class="border px-4 py-2">
                Present data-driven insights or user feedback that highlight the scope of the problem.
              </td>
            </tr>
            <tr>
              <td class="border px-4 py-2 font-bold">Hypothesis</td>
              <td class="border px-4 py-2">
                State the assumption about how the change will improve the product or user experience.
              </td>
            </tr>
            <tr>
              <td class="border px-4 py-2 font-bold">Experiment</td>
              <td class="border px-4 py-2">
                Outline the experiment setup, including test variations and control group comparison.
              </td>
            </tr>
            <tr>
              <td class="border px-4 py-2 font-bold">Success Metrics</td>
              <td class="border px-4 py-2">
                Define the key metrics that will measure the success of the experiment.
              </td>
            </tr>
            <tr>
              <td class="border px-4 py-2 font-bold">Control Metrics</td>
              <td class="border px-4 py-2">
                Identify metrics to track any unintended negative impacts and assess risks.
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  );
};

export default withCourseNavigation(ABTestingQuestionsPage);
