import React from 'react';
import withCourseNavigation from '../hoc/withCourseNavigation';

const AnalyticalInterviewsRubric = () => {
  return (
    <div className="course-content">
      <h1 className="course-title">Evaluation Criteria of Analytical Questions</h1>

      <p className="course-intro">
        In analytical interview rounds, you will be primarily assessed on your analytical skills, critical thinking, and culture fit. Many companies use a five-point scale to rate these core qualities, ranging from "very weak" to "very strong." Interviewers share their feedback on each skill during the hiring discussion.
      </p>

      <p className="course-intro">
        Analytical skills focus on data literacy (can you work with and interpret data?) and comfort with metrics (do you understand which metrics matter in a given situation?). Critical thinking skills involve diagnosing problems, prioritizing solutions, and executing a plan. Culture fit signals include communication, collaboration, and curiosity.
      </p>

      <section className="interview-section">
        <h2 className="section-title">Core Skills to Demonstrate in Analytical Interviews</h2>

        {["Data Literacy", "Comfort with Metrics", "Ability to Diagnose Problems", "Ability to Prioritize", "Ability to Execute a Plan", "Communication", "Collaboration", "Curiosity"].map((skill, index) => (
          <div key={index} className="skill-section">
            <h3 className="subsection-title">{index + 1}. {skill}</h3>
            <p>{getSkillDescription(skill)}</p>
            <ul className="bullet-list">
              <li><strong>Very Weak or Missing:</strong> {getRubricPoint(skill, "Very Weak or Missing")}</li>
              <li><strong>Weak:</strong> {getRubricPoint(skill, "Weak")}</li>
              <li><strong>Neutral:</strong> {getRubricPoint(skill, "Neutral")}</li>
              <li><strong>Strong:</strong> {getRubricPoint(skill, "Strong")}</li>
              <li><strong>Very Strong:</strong> {getRubricPoint(skill, "Very Strong")}</li>
            </ul>
            <p><strong>Example: </strong> {getExample(skill)}</p>
          </div>
        ))}
      </section>

      <div class="container mx-auto p-4">
  <h1 class="text-2xl font-bold mb-4">Core Skills to Demonstrate in Analytical Interviews</h1>

  <table class="table-auto w-full border-collapse border border-gray-300">
    <thead>
      <tr class="bg-gray-100">
        <th class="border px-4 py-2 text-left">Core Skill</th>
        <th class="border px-4 py-2 text-left">Very Weak</th>
        <th class="border px-4 py-2 text-left">Weak</th>
        <th class="border px-4 py-2 text-left">Neutral</th>
        <th class="border px-4 py-2 text-left">Strong</th>
        <th class="border px-4 py-2 text-left">Very Strong</th>
        <th class="border px-4 py-2 text-left">Example</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="border px-4 py-2 font-bold">Data Literacy</td>
        <td class="border px-4 py-2">Did not use data</td>
        <td class="border px-4 py-2">Missed obvious patterns</td>
        <td class="border px-4 py-2">Reasonable but missed key insights</td>
        <td class="border px-4 py-2">Used data effectively</td>
        <td class="border px-4 py-2">Extracted valuable insights, identified patterns</td>
        <td class="border px-4 py-2">Identify key metrics on Flipkart like conversion rates and suggest A/B tests</td>
      </tr>
      <tr>
        <td class="border px-4 py-2 font-bold">Comfort with Metrics</td>
        <td class="border px-4 py-2">Did not understand key metrics</td>
        <td class="border px-4 py-2">Struggled to define relevant metrics</td>
        <td class="border px-4 py-2">Reasonable knowledge, but missed the best metrics</td>
        <td class="border px-4 py-2">Discussed pros/cons of metrics, made good choices</td>
        <td class="border px-4 py-2">Thorough discussion, suggested improvements</td>
        <td class="border px-4 py-2">Track DAUs, order value, and churn rate for Zomato's subscription success</td>
      </tr>
      <tr>
        <td class="border px-4 py-2 font-bold">Ability to Diagnose Problems</td>
        <td class="border px-4 py-2">Jumped to conclusions</td>
        <td class="border px-4 py-2">Diagnosed poorly with errors</td>
        <td class="border px-4 py-2">Adequate diagnosis, missed nuances</td>
        <td class="border px-4 py-2">Asked right questions, scoped problem</td>
        <td class="border px-4 py-2">Identified key insights, clear action plan</td>
        <td class="border px-4 py-2">Diagnose user retention issues on PhonePe by analyzing drop-off points</td>
      </tr>
      <tr>
        <td class="border px-4 py-2 font-bold">Ability to Prioritize</td>
        <td class="border px-4 py-2">Failed to prioritize</td>
        <td class="border px-4 py-2">Poor prioritization</td>
        <td class="border px-4 py-2">Prioritized well with guidance</td>
        <td class="border px-4 py-2">Prioritized effectively, balanced impact and cost</td>
        <td class="border px-4 py-2">Maximized impact with clear action plan</td>
        <td class="border px-4 py-2">For Paytm, prioritize transaction success rates before UI tweaks</td>
      </tr>
      <tr>
        <td class="border px-4 py-2 font-bold">Ability to Execute a Plan</td>
        <td class="border px-4 py-2">Failed to provide a clear plan</td>
        <td class="border px-4 py-2">Struggled to explain the plan</td>
        <td class="border px-4 py-2">Well-defined plan but missed key points</td>
        <td class="border px-4 py-2">Provided a detailed, actionable plan</td>
        <td class="border px-4 py-2">Clear, executable plan with KPIs and alternatives</td>
        <td class="border px-4 py-2">Outline steps to improve driver satisfaction for Ola</td>
      </tr>
      <tr>
        <td class="border px-4 py-2 font-bold">Communication</td>
        <td class="border px-4 py-2">Could not communicate clearly</td>
        <td class="border px-4 py-2">Poor communication</td>
        <td class="border px-4 py-2">Inconsistent communication</td>
        <td class="border px-4 py-2">Articulated clearly and concisely</td>
        <td class="border px-4 py-2">Proactive communication, anticipated questions</td>
        <td class="border px-4 py-2">Explain your steps when proposing new Swiggy feature</td>
      </tr>
      <tr>
        <td class="border px-4 py-2 font-bold">Collaboration</td>
        <td class="border px-4 py-2">Did not engage with interviewer</td>
        <td class="border px-4 py-2">Struggled to stay on track</td>
        <td class="border px-4 py-2">Took lead but needed prompts</td>
        <td class="border px-4 py-2">Engaged and led discussion</td>
        <td class="border px-4 py-2">Seamlessly collaborated, made it a two-way discussion</td>
        <td class="border px-4 py-2">Ask for feedback while running an A/B test on Paytm</td>
      </tr>
      <tr>
        <td class="border px-4 py-2 font-bold">Curiosity</td>
        <td class="border px-4 py-2">Did not explore the problem</td>
        <td class="border px-4 py-2">Asked basic questions but didn't dig deeper</td>
        <td class="border px-4 py-2">Showed curiosity but missed key areas</td>
        <td class="border px-4 py-2">Asked great questions, actively engaged</td>
        <td class="border px-4 py-2">Asked thoughtful questions, uncovered insights</td>
        <td class="border px-4 py-2">Ask about session length and user behavior on Hotstar</td>
      </tr>
    </tbody>
  </table>
</div>



    </div>
  );
};

const getSkillDescription = (skill) => {
  const descriptions = {
    "Data Literacy": "Data literacy is a fundamental PM skill. Interviewers want to see if you can identify patterns in data, suggest relevant tests, and draw reasonable conclusions from your findings.",
    "Comfort with Metrics": "A PM must be comfortable with metrics, particularly around user acquisition, retention, and usage. It's important to know which metric to use in different scenarios and what insights you can draw from each.",
    "Ability to Diagnose Problems": "A PM's ability to diagnose a problem is critical in problem-solving. You'll need to break down complex problems and create a clear action plan.",
    "Ability to Prioritize": "Once you've scoped the problem, the next step is prioritizing which hypotheses to test or problems to solve first. The ability to prioritize based on impact and feasibility is a key PM skill.",
    "Ability to Execute a Plan": "Execution is crucial in a PM's role. Once you've diagnosed the problem and prioritized the solutions, it's time to implement the plan. Interviewers want to hear your step-by-step explanation of how you'd execute and measure success.",
    "Communication": "Clear communication is critical in PM interviews. Your ability to explain your thought process, respond to feedback, and articulate your decisions will be continuously evaluated.",
    "Collaboration": "Collaboration is a key trait for PMs, and analytical interviews often simulate this. You should use the interviewer as a resource and involve them in the discussion.",
    "Curiosity": "A PM's curiosity drives problem-solving. Interviewers want to see your passion for digging into problems, asking thoughtful questions, and uncovering insights."
  };
  return descriptions[skill];
};

const getRubricPoint = (skill, level) => {
  const rubricPoints = {
    "Data Literacy": {
      "Very Weak or Missing": "Did not use data to answer the question.",
      "Weak": "Missed obvious data patterns or failed to draw conclusions from the data.",
      "Neutral": "Reasonable understanding of data but missed some key insights.",
      "Strong": "Used data effectively, asked insightful questions, and made logical assumptions.",
      "Very Strong": "Extracted valuable insights from data, identified patterns, and suggested useful reports or tests."
    },
    "Comfort with Metrics": {
      "Very Weak or Missing": "Did not understand key metrics.",
      "Weak": "Struggled to define relevant metrics or compare different metrics.",
      "Neutral": "Reasonable knowledge, but missed the most appropriate metrics for the situation.",
      "Strong": "Clearly discussed the pros and cons of various metrics, made solid choices.",
      "Very Strong": "Thoroughly discussed metrics and recommended ways to improve the analytical process."
    },
    "Ability to Diagnose Problems": {
      "Very Weak or Missing": "Jumped to conclusions without understanding the problem.",
      "Weak": "Tried to diagnose the problem but made significant errors.",
      "Neutral": "Adequate diagnosis but missed some nuances.",
      "Strong": "Asked the right questions, scoped the problem effectively.",
      "Very Strong": "Identified key insights and set up a clear plan for testing and solving the issue."
    },
    "Ability to Prioritize": {
      "Very Weak or Missing": "Failed to prioritize.",
      "Weak": "Prioritization was sloppy or poorly thought out.",
      "Neutral": "Prioritized well but needed nudging.",
      "Strong": "Prioritized effectively, accounting for cost, urgency, and feasibility.",
      "Very Strong": "Prioritized with maximum impact, minimal cost, and a strong action plan."
    },
    "Ability to Execute a Plan": {
      "Very Weak or Missing": "Failed to provide a clear plan.",
      "Weak": "Struggled to explain the plan in a way that could be implemented.",
      "Neutral": "Well-defined plan but missed key points.",
      "Strong": "Identified the problem well and provided a detailed, actionable plan.",
      "Very Strong": "Presented a clear, executable plan with KPIs, anticipated bottlenecks, and alternative solutions."
    },
    "Communication": {
      "Very Weak or Missing": "Could not communicate clearly despite prompts.",
      "Weak": "Poor communication throughout the interview.",
      "Neutral": "Communication was inconsistent; clear in some areas, vague in others.",
      "Strong": "Articulated thoughts clearly and concisely.",
      "Very Strong": "Proactive communication, anticipated questions, and checked in with the interviewer."
    },
    "Collaboration": {
      "Very Weak or Missing": "Did not engage with the interviewer.",
      "Weak": "Struggled to stay on track without guidance.",
      "Neutral": "Took the lead but needed prompts from the interviewer.",
      "Strong": "Engaged the interviewer throughout and led the discussion.",
      "Very Strong": "Seamlessly collaborated with the interviewer, turning the interview into a two-way discussion."
    },
    "Curiosity": {
      "Very Weak or Missing": "Did not show interest in exploring the problem.",
      "Weak": "Asked basic questions but failed to dig deeper.",
      "Neutral": "Showed some curiosity but missed key areas.",
      "Strong": "Asked great questions, actively engaged with the problem.",
      "Very Strong": "Asked insightful, thoughtful questions that helped uncover critical information."
    }
  };
  return rubricPoints[skill][level];
};

const getExample = (skill) => {
  const examples = {
    "Data Literacy": "When discussing the performance of an e-commerce feature on Flipkart, you should be able to identify key metrics like conversion rates and order frequency. If you notice a dip in conversion rates, suggest running A/B tests on the checkout process and analyze patterns in user drop-offs.",
    "Comfort with Metrics": "For a food delivery platform like Zomato, be prepared to explain why you'd track daily active users (DAUs), average order value, and churn rate when analyzing the success of a new subscription service.",
    "Ability to Diagnose Problems": "If you're working on improving user retention for a fintech app like PhonePe, start by diagnosing if the problem lies in user onboarding, app navigation, or service satisfaction. Ask about specific user drop-off points and use data to back your assumptions.",
    "Ability to Prioritize": "If working on Paytm's digital wallet feature, prioritize addressing the highest-impact issues first, such as improving transaction success rates, before focusing on smaller features like user interface tweaks.",
    "Ability to Execute a Plan": "For a ride-sharing app like Ola, if you're tasked with improving driver satisfaction, outline a step-by-step plan to conduct surveys, A/B test new features like payout bonuses, and measure the effectiveness through metrics like retention rate and driver engagement.",
    "Communication": "When discussing a new feature for an app like Swiggy, ensure that you explain your assumptions, thought process, and decisions step by step so that the interviewer can follow your logic.",
    "Collaboration": "If you're running an A/B test on a feature in the Paytm app, ask the interviewer for feedback or additional context. This shows that you're open to collaboration and value input.",
    "Curiosity": "When discussing improving user engagement on an app like Hotstar, you could ask questions like, 'What is the average session length on the platform, and how do users interact with various types of content?' This shows a deeper curiosity about the problem and its underlying causes."
  };
  return examples[skill];
};

export default withCourseNavigation(AnalyticalInterviewsRubric);