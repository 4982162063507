import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { supabase } from './supabaseClient';
import ReactGA from 'react-ga4';
import Header from './components/Layout/Header';
import Sidebar from './components/Layout/Sidebar';
import Login from './components/Login';
import EmailLogin from './components/EmailLogin';
import Signup from './components/Signup';
import Profile from './components/Profile';

// Introduction
import Welcome from './components/Introduction/Welcome';
import ResumeOptimisation from './components/Introduction/Resume';
import JobApplicationStrategies from './components/Introduction/JobApplicationStrategy';
import QuestionTypes from './components/Introduction/QuestionTypes';
import Framework from './components/Introduction/Framework';
import Grading from './components/Introduction/Grading';
import Glossary from './components/Introduction/Glossary';

// Estimation
import EstimationOverview from './components/Estimation/Overview';
import EstimationAnswering from './components/Estimation/Answering';
import EstimationStrategies from './components/Estimation/Strategies';
import EstimationReference from './components/Estimation/Reference';
import EstimationPractice from './components/Estimation/Practice';

// Analytical
import AnalyticalIntro from './components/Analytical/Intro';
import ABTesting from './components/Analytical/ABTesting';
import Metrics from './components/Analytical/Metrics';
import AnalyticalRubric from './components/Analytical/Rubric';
import AnalyticalPractice from './components/Analytical/Practice';

// Execution
import ExecutionIntro from './components/Execution/Intro';
import RootCause from './components/Execution/RootCause';
import DecisionMaking from './components/Execution/DecisionMaking';
import ExecutionRubric from './components/Execution/Rubric';
import ExecutionPractice from './components/Execution/Practice';

// Product Design
import ProductDesignIntro from './components/ProductDesign/Intro';
import ProductDesignAnswering from './components/ProductDesign/Answering';
import ProductDesignBrainstorming from './components/ProductDesign/Brainstorming';
import ProductDesignVision from './components/ProductDesign/Vision';
import ProductDesignRubric from './components/ProductDesign/Rubric';
import ProductDesignPractice from './components/ProductDesign/Practice';

// Product Strategy
import ProductStrategyIntro from './components/ProductStrategy/Intro';
import ProductStrategyAnswering from './components/ProductStrategy/Answering';
import ProductStrategyGoToMarket from './components/ProductStrategy/GoToMarket';
import ProductStrategyPricing from './components/ProductStrategy/Pricing';
import ProductStrategyGrowth from './components/ProductStrategy/Growth';
import ProductStrategyRubric from './components/ProductStrategy/Rubric';
import ProductStrategyPractice from './components/ProductStrategy/Practice';

// Technical Questions
import TechnicalIntro from './components/Technical/Intro';
import TechnicalKeyConcepts from './components/Technical/KeyConcepts';
import TechnicalTackling from './components/Technical/Tackling';
import TechnicalEvaluating from './components/Technical/Evaluating';
import TechnicalPractice from './components/Technical/Practice';

// Behavioral Interviews
import BehavioralMastering from './components/Behavioral/Mastering';
import BehavioralStoryBank from './components/Behavioral/StoryBank';
import BehavioralImpact from './components/Behavioral/Impact';
import BehavioralSacrifices from './components/Behavioral/Sacrifices';
import BehavioralCustomerProblems from './components/Behavioral/CustomerProblems';
import BehavioralConflicts from './components/Behavioral/Conflicts';
import BehavioralWhyCompany from './components/Behavioral/WhyCompany';

function GATracker() {
  const location = useLocation();

  useEffect(() => {
    // Track page view whenever location changes
    ReactGA.send({ 
      hitType: 'pageview', 
      page: location.pathname,
      title: document.title
    });
  }, [location]);

  return null;
}

function App() {
  const [user, setUser] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    //Initialise GA
    ReactGA.initialize('G-838E4JJQ6V');
    ReactGA.send('pageview');

    supabase.auth.getSession().then(({ data: { session } }) => {
      setUser(session?.user ?? null);
    });

    // Listen for auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user ?? null);
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    setIsSidebarOpen(false);
  }, [location]);

  const handleLogout = async () => {
    await supabase.auth.signOut();
    setUser(null);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <>
    <GATracker />
    <div className="flex flex-col min-h-screen">
      <Header user={user} onLogout={handleLogout} toggleSidebar={toggleSidebar} />
      <div className="flex flex-1 overflow-hidden">
        {user && <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} />}
        <main className="flex-1 py-6 overflow-y-auto">
          <Routes>
            <Route path="/login" element={!user ? <Login setUser={setUser} /> : <Navigate to="/dashboard/intro/welcome" replace />} />
            <Route path="/email-login" element={!user ? <EmailLogin setUser={setUser} /> : <Navigate to="/dashboard/intro/welcome" replace />} />
            <Route path="/signup-user" element={!user ? <Signup setUser={setUser} /> : <Navigate to="/dashboard/intro/welcome" replace />} />
            <Route path="/profile" element={user ? <Profile /> : <Navigate to="/login" replace />} />
            <Route 
              path="/dashboard/*" 
              element={
                user ? (
                  <Routes>
                    {/* Introduction Routes */}
                    <Route path="intro/welcome" element={<Welcome />} />
                    <Route path="intro/resume-optimisation" element={<ResumeOptimisation />} />
                    <Route path="intro/job-application-strategy" element={<JobApplicationStrategies />} />
                    <Route path="intro/question-types" element={<QuestionTypes />} />
                    <Route path="intro/framework" element={<Framework />} />
                    <Route path="intro/grading" element={<Grading />} />
                    <Route path="intro/glossary" element={<Glossary />} />

                    {/* Estimation Routes */}
                    <Route path="estimation/overview" element={<EstimationOverview />} />
                    <Route path="estimation/answering" element={<EstimationAnswering />} />
                    <Route path="estimation/strategies" element={<EstimationStrategies />} />
                    <Route path="estimation/reference" element={<EstimationReference />} />
                    <Route path="estimation/practice" element={<EstimationPractice />} />

                    {/* Analytical Routes */}
                    <Route path="analytical/intro" element={<AnalyticalIntro />} />
                    <Route path="analytical/ab-testing" element={<ABTesting />} />
                    <Route path="analytical/metrics" element={<Metrics />} />
                    <Route path="analytical/rubric" element={<AnalyticalRubric />} />
                    <Route path="analytical/practice" element={<AnalyticalPractice />} />

                    {/* Execution Routes */}
                    <Route path="execution/intro" element={<ExecutionIntro />} />
                    <Route path="execution/root-cause" element={<RootCause />} />
                    <Route path="execution/decision-making" element={<DecisionMaking />} />
                    <Route path="execution/rubric" element={<ExecutionRubric />} />
                    <Route path="execution/practice" element={<ExecutionPractice />} />

                    {/* Product Design Routes */}
                    <Route path="product-design/intro" element={<ProductDesignIntro />} />
                    <Route path="product-design/answering" element={<ProductDesignAnswering />} />
                    <Route path="product-design/brainstorming" element={<ProductDesignBrainstorming />} />
                    <Route path="product-design/vision" element={<ProductDesignVision />} />
                    <Route path="product-design/rubric" element={<ProductDesignRubric />} />
                    <Route path="product-design/practice" element={<ProductDesignPractice />} />

                    {/* Product Strategy Routes */}
                    <Route path="product-strategy/intro" element={<ProductStrategyIntro />} />
                    <Route path="product-strategy/answering" element={<ProductStrategyAnswering />} />
                    <Route path="product-strategy/go-to-market" element={<ProductStrategyGoToMarket />} />
                    <Route path="product-strategy/pricing" element={<ProductStrategyPricing />} />
                    <Route path="product-strategy/growth" element={<ProductStrategyGrowth />} />
                    <Route path="product-strategy/rubric" element={<ProductStrategyRubric />} />
                    <Route path="product-strategy/practice" element={<ProductStrategyPractice />} />

                    {/* Technical Questions Routes */}
                    <Route path="technical/intro" element={<TechnicalIntro />} />
                    <Route path="technical/key-concepts" element={<TechnicalKeyConcepts />} />
                    <Route path="technical/tackling" element={<TechnicalTackling />} />
                    <Route path="technical/evaluating" element={<TechnicalEvaluating />} />
                    <Route path="technical/practice" element={<TechnicalPractice />} />

                    {/* Behavioral Interviews Routes */}
                    <Route path="behavioral/mastering" element={<BehavioralMastering />} />
                    <Route path="behavioral/story-bank" element={<BehavioralStoryBank />} />
                    <Route path="behavioral/impact" element={<BehavioralImpact />} />
                    <Route path="behavioral/sacrifices" element={<BehavioralSacrifices />} />
                    <Route path="behavioral/customer-problems" element={<BehavioralCustomerProblems />} />
                    <Route path="behavioral/conflicts" element={<BehavioralConflicts />} />
                    <Route path="behavioral/why-company" element={<BehavioralWhyCompany />} />

                    <Route path="*" element={<Navigate to="intro/welcome" replace />} />
                  </Routes>
                ) : (
                  <Navigate to="/login" replace />
                )
              } 
            />
            <Route path="/" element={<Navigate to={user ? "/dashboard/intro/welcome" : "/login"} replace />} />
            <Route path="*" element={<Navigate to={user ? "/dashboard/intro/welcome" : "/login"} replace />} />
          </Routes>
        </main>
      </div>
    </div>
    </>
  );
}

export default App;