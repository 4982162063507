import React from 'react';
import withCourseNavigation from '../hoc/withCourseNavigation';

const DecisionMakingGuide = () => {
  return (
    <div className="course-content">
      <h1 className="course-title">How to Answer Decision-Making Questions</h1>

      <p className="course-intro">
        Decision-making questions are all about putting you in the shoes of a PM, where you need to make important product decisions. You'll be given a scenario with competing options, and there's often no clear winner.
      </p>

      <div className="interview-section">
        <h2 className="section-title">Example Question</h2>
        <blockquote className="example-quote">
          "You're a PM at Hotstar. Your team is working on a redesign that increases watch time but decreases user engagement (likes and comments). Should you go ahead and release it?"
        </blockquote>
      </div>

      <section className="interview-section">
        <h2 className="section-title">What Interviewers Are Looking For</h2>
        <p>
          In decision-making questions, interviewers are assessing similar skills as with root cause analysis questions, but with an added focus on:
        </p>
        <ul className="bullet-list">
          <li><strong>Product thinking</strong> – how well you understand the product and its role in the business.</li>
          <li><strong>Strategic thinking</strong> – whether you can think about the big picture and long-term impact.</li>
          <li><strong>Ability to evaluate options</strong> – how you balance trade-offs and make data-driven decisions.</li>
        </ul>
        <p>
          This is a great chance to showcase your domain expertise, so don't hesitate to bring in relevant personal experiences.
        </p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">A Framework for Answering Decision-Making Questions</h2>
        <p>
          Decision-making questions are usually meant to be solved in about 25 minutes. Here's a four-step framework to help you navigate them efficiently:
        </p>
        <ol className="numbered-list">
          <li>Clarify and gather information</li>
          <li>Set decision criteria</li>
          <li>Evaluate options</li>
          <li>Decide and recap</li>
        </ol>
        <p>Let's dive into these steps with an example.</p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Step-by-Step Breakdown</h2>

        <div className="step-explanation">
          <h3 className="subsection-title">Step 1: Clarify and Gather Information</h3>
          <p>
            Decision-making questions often lack specific details, so start by asking clarifying questions. Gather as much context as possible about how the decision impacts the product and the business.
          </p>
          <p>For example, in the Hotstar redesign scenario, you'd want to ask:</p>
          <ul className="bullet-list">
            <li>"How does Hotstar's growth strategy prioritize engagement vs. watch time?"</li>
            <li>"By how much has watch time increased, and how much has engagement dropped?"</li>
            <li>"Is this change happening across all content types or just specific categories?"</li>
          </ul>
        </div>

        <div className="step-explanation">
          <h3 className="subsection-title">Step 2: Set Decision Criteria</h3>
          <p>
            Now that you've gathered enough information, set decision criteria. This is similar to defining goals—what's the most important factor in making this decision?
          </p>
          <p>
            Your decision criteria should focus on what matters most for the product and company. Is it more important to boost engagement, or is maximizing watch time the key priority? Your decision should align with strategic goals like monetization, growth, or market positioning.
          </p>
          <div className="example-box">
            <h4>Example</h4>
            <p>For our Hotstar example, the decision criteria could look like this:</p>
            <ul className="bullet-list">
              <li>"Hotstar's strategic focus is to grow its user base and increase retention."</li>
              <li>"Watch time directly contributes to ad revenue and subscriber growth."</li>
              <li>"However, engagement (likes and comments) is also important because it fosters community and keeps users coming back."</li>
            </ul>
          </div>
        </div>

        <div className="step-explanation">
          <h3 className="subsection-title">Step 3: Evaluate Options</h3>
          <p>
            Next, consider the potential outcomes of each option. If you weren't given specific options, brainstorm a few alternatives. Weigh the pros and cons of each solution.
          </p>
          <div className="example-box">
            <h4>Example</h4>
            <p>Here's how you might evaluate the options in our Hotstar scenario:</p>
            <ul className="bullet-list">
              <li><strong>Shipping the redesign:</strong> Watch time increases, bringing in more ad revenue and possibly higher subscriptions. However, reduced engagement could weaken the community aspect, leading to lower retention in the long run.</li>
              <li><strong>Not shipping the redesign:</strong> Likes and comments remain high, keeping users engaged and fostering a sense of community. But, without the increase in watch time, Hotstar might lose out on additional revenue.</li>
            </ul>
          </div>
        </div>

        <div className="step-explanation">
          <h3 className="subsection-title">Step 4: Decide and Recap</h3>
          <p>
            Once you've weighed the options, make your decision and explain the reasoning behind it. Recap your decision criteria and how each option stacks up against them. This is also a good time to mention any mitigation strategies you might use to address potential downsides.
          </p>
          <div className="example-box">
            <h4>Example</h4>
            <blockquote className="example-quote">
              "Based on Hotstar's goals of growing user retention and increasing watch time, I would choose to ship the redesign. While engagement might drop initially, the increased watch time will boost ad revenue and subscriber growth, which is key for the platform. To mitigate the drop in engagement, we could explore ways to encourage users to interact, such as adding reminders to like and comment or making engagement features more prominent in the redesign."
            </blockquote>
          </div>
        </div>
      </section>

      <section className="interview-section">
        <h2 className="section-title">"Okay" vs. "Good" vs. "Great" Answers</h2>
        <ul className="bullet-list">
          <li><strong>Okay answers:</strong> You weigh the trade-offs reasonably well, but don't go deep into the product's long-term strategy.</li>
          <li><strong>Good answers:</strong> You set clear decision criteria and evaluate the trade-offs in the context of the product and business goals.</li>
          <li><strong>Great answers:</strong> You demonstrate deep product and market insight, clearly outlining the impact of each option and possibly suggesting a compromise or mitigation strategy.</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Common Pitfalls to Avoid</h2>
        <ul className="bullet-list">
          <li><strong>Making decisions too quickly:</strong> Don't jump to conclusions. Take your time to explore all the options thoroughly.</li>
          <li><strong>Not setting decision criteria:</strong> Without criteria, it's easy to get lost in the details and lose sight of the bigger picture.</li>
        </ul>
      </section>

    </div>
  );
};

export default withCourseNavigation(DecisionMakingGuide);
