import React from 'react';
import withCourseNavigation from '../hoc/withCourseNavigation';

const AnalyticalQuestionsIntro = () => {
  return (
    <div className="">
      <h1 className="course-title">Introduction to Analytical Questions</h1>

      <p className="course-intro">
        Ok, let's embrace it - you need to be analytical to succeed in PM role. Analytical questions focus on testing your logical reasoning, problem-solving skills, and your ability to work with data. These questions are designed to see how well you can break down complex problems, set measurable goals, and make decisions based on data.
      </p>

      <p className="course-intro">
        For these questions, it's important to explain your choices clearly and back them up with good reasons. It also demonstrates your product sense in certain cases.
      </p>
      <div className="image-container mb-6">
          <img 
            src="https://i.imgur.com/SjRtvdD.png" 
            alt="Skills for product managers" 
            className="w-full md:w-3/5 h-auto rounded-lg"
          />
        </div>
      <section className="interview-section">
        <h3 className="subsection-title">1. Metrics Questions</h3>
        <p>
          Metrics are essential for tracking progress and ensuring that your product is performing as expected. These questions check how well you can pick the right metrics for the problem or goal. It's not just about listing metrics—it's about understanding what to track and connecting it back to the main business goal.
        </p>
        <p><strong>Example Questions:</strong></p>
        <ul className="bullet-list">
          <li>"If you were a PM for Rapido, what metrics would you use to track the health of the app?"</li>
          <li>"Define a north star metric for subscription service, Swiggy One."</li>
          <li>"How would you define success metrics for a new feature in Zomato District app?"</li>
        </ul>
        <p>
          In this module, we'll walk through common metrics, and introduce the <strong>GAME framework</strong> to help you answer these questions logically.
        </p>

        <h3 className="subsection-title">2. A/B Testing Questions</h3>
        <p>
          In most of B2C companies, your goal as PM would be run experiments. Your normal week will look like running at least 2 experiments per week (depends on company for sure). These questions assess your ability to design experiments, interpret results, and make informed decisions based on user behavior.
        </p>
        <p><strong>Example Questions:</strong></p>
        <ul className="bullet-list">
          <li>"What A/B tests would you run on Flipkart's homepage to increase conversion rates?"</li>
          <li>"Design an A/B test to reduce user frustration with Ola's ride cancellation feature."</li>
          <li>"How would you A/B test a new dynamic pricing model for OYO?"</li>
        </ul>
        <p>
          We'll cover an approach for structuring your answers to A/B testing questions, including how to set up the experiment, choose the right metrics, analyze the results, and plan next steps.
        </p>

        <h3 className="subsection-title">3. Execution Questions</h3>
         <p>
          Execution questions evaluate your problem-solving abilities, which are critical in product management. While these questions can vary from company to company, they focus on your approach to diagnosing and resolving issues. These questions may not appear in every analytical interview, but companies like Meta often incorporate execution-related scenarios.
        </p>

        <p><strong>Example Questions:</strong></p>

        <ul className="bullet-list">
          <li>"You're a PM at Paytm, and you notice a significant drop in transactions for UPI payments. How would you investigate and address this?"</li>
          <li>"As a product manager for Swiggy, delivery times have increased unexpectedly. What steps would you take to resolve this issue?"</li>
          <li>"You're the PM for Razorpay, and one of the merchants reports a rise in payment failures. How would you tackle this problem?"</li>
        </ul>

        <h3 className="subsection-title">4. Estimation Questions</h3>
        <p>
          Estimation questions test your ability to break down complex, unclear problems, make logical assumptions, and estimate the size or scale of something. While very few companies ask estimation questions, they are valuable skills to develop for tasks like assessing market potential in product strategy discussions. These questions help you think critically and practice making educated guesses based on limited information.
        </p>

        <p><strong>Example Questions:</strong></p>

        <ul className="bullet-list">
          <li>"Estimate the market size for electric scooters in India by 2026."</li>
          <li>"What is the total addressable market (TAM) for Zomato in India?"</li>
          <li>"How much would it cost to run Hotstar during an IPL match day?"</li>
        </ul>

      </section>
    </div>
  );
};

export default withCourseNavigation(AnalyticalQuestionsIntro);