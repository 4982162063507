// /src/components/Layout/Sidebar.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChevronRight, ChevronDown } from 'lucide-react';

const SidebarItem = ({ title, path, isActive, onClick }) => (
  <Link to={path} className={`block py-2 px-4 text-sm ${isActive ? 'text-indigo-600 bg-indigo-50 font-medium' : 'text-gray-600 hover:bg-gray-50'}`} onClick={onClick}>
    {title}
  </Link>
);

const CollapsibleSection = ({ title, children, defaultOpen = false }) => {
  const [isOpen, setIsOpen] = React.useState(defaultOpen);
  
  return (
    <div className="border-b border-gray-200 last:border-b-0">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-between w-full text-left text-sm font-semibold text-gray-900 p-4"
      >
        {title}
        {isOpen ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
      </button>
      {isOpen && <div className="pb-2">{children}</div>}
    </div>
  );
};

const Sidebar = ({ isOpen, onClose }) => {
  const location = useLocation();

  return (
    <aside className={`
      w-64 bg-white border-r border-gray-200 overflow-hidden flex flex-col sidebar
      fixed left-0 top-0 bottom-0 z-40 pt-16
      transform transition-transform duration-300 ease-in-out
      ${isOpen ? 'translate-x-0' : '-translate-x-full'}
      md:translate-x-0 md:static md:h-auto
    `}>
      <div className="p-4 border-b border-gray-200">
        <h2 className="text-xl font-semibold">PM Interview Mastery</h2>
      </div>
      <div className="overflow-y-auto flex-grow hover:scrollbar-thin hover:scrollbar-thumb-gray-300 hover:scrollbar-track-gray-100 pb-20">
        <CollapsibleSection title="Introduction" defaultOpen={true}>
          <SidebarItem title="Welcome to PM Mastery" path="/dashboard/intro/welcome" isActive={location.pathname === '/dashboard/intro/welcome'} />
          <SidebarItem title="Resume Optimisation" path="/dashboard/intro/resume-optimisation" isActive={location.pathname === '/dashboard/intro/resume-optimisation'} />
          <SidebarItem title="Job Application Strategy" path="/dashboard/intro/job-application-strategy" isActive={location.pathname === '/dashboard/intro/job-application-strategy'} />
          <SidebarItem title="PM Interview Question Types" path="/dashboard/intro/question-types" isActive={location.pathname === '/dashboard/intro/question-types'} />
          <SidebarItem title="Mastering the Interview Framework" path="/dashboard/intro/framework" isActive={location.pathname === '/dashboard/intro/framework'} />
          <SidebarItem title="Essential PM Concepts Glossary" path="/dashboard/intro/glossary" isActive={location.pathname === '/dashboard/intro/glossary'} />
        </CollapsibleSection>

        <CollapsibleSection title="Analytical Questions">
          <SidebarItem title="Analytical Thinking: An Introduction" path="/dashboard/analytical/intro" isActive={location.pathname === '/dashboard/analytical/intro'} />
          <SidebarItem title="A/B Testing Analysis" path="/dashboard/analytical/ab-testing" isActive={location.pathname === '/dashboard/analytical/ab-testing'} />
          <SidebarItem title="Metric-Based Questions" path="/dashboard/analytical/metrics" isActive={location.pathname === '/dashboard/analytical/metrics'} />
          <SidebarItem title="Analytical Question Evaluation Criteria" path="/dashboard/analytical/rubric" isActive={location.pathname === '/dashboard/analytical/rubric'} />
          <SidebarItem title="Analytical Skills Practice Lab" path="/dashboard/analytical/practice" isActive={location.pathname === '/dashboard/analytical/practice'} />
        </CollapsibleSection>

        <CollapsibleSection title="Execution Questions">
          <SidebarItem title="Execution Excellence: An Introduction" path="/dashboard/execution/intro" isActive={location.pathname === '/dashboard/execution/intro'} />
          <SidebarItem title="Mastering Root Cause Analysis" path="/dashboard/execution/root-cause" isActive={location.pathname === '/dashboard/execution/root-cause'} />
          <SidebarItem title="Strategic Decision-Making Skills" path="/dashboard/execution/decision-making" isActive={location.pathname === '/dashboard/execution/decision-making'} />
        </CollapsibleSection>

        <CollapsibleSection title="Product Design Questions">
          <SidebarItem title="Introduction to Product Design" path="/dashboard/product-design/intro" isActive={location.pathname === '/dashboard/product-design/intro'} />
          <SidebarItem title="Answering Product Design Questions" path="/dashboard/product-design/answering" isActive={location.pathname === '/dashboard/product-design/answering'} />
          <SidebarItem title="Coming with Product Ideas" path="/dashboard/product-design/brainstorming" isActive={location.pathname === '/dashboard/product-design/brainstorming'} />
        </CollapsibleSection>

        <CollapsibleSection title="Product Strategy Questions">
          <SidebarItem title="Intro to Product Strategy" path="/dashboard/product-strategy/intro" isActive={location.pathname === '/dashboard/product-strategy/intro'} />
          <SidebarItem title="Handling Product Strategy Questions" path="/dashboard/product-strategy/answering" isActive={location.pathname === '/dashboard/product-strategy/answering'} />
          <SidebarItem title="Approaching Go-to-Market Questions" path="/dashboard/product-strategy/go-to-market" isActive={location.pathname === '/dashboard/product-strategy/go-to-market'} />
          <SidebarItem title="Approaching Pricing Strategy Questions" path="/dashboard/product-strategy/pricing" isActive={location.pathname === '/dashboard/product-strategy/pricing'} />
          <SidebarItem title="Approaching Growth Strategy Questions" path="/dashboard/product-strategy/growth" isActive={location.pathname === '/dashboard/product-strategy/growth'} />    
        </CollapsibleSection>

        <CollapsibleSection title="Estimation Questions">
          <SidebarItem title="Estimation: An Overview" path="/dashboard/estimation/overview" isActive={location.pathname === '/dashboard/estimation/overview'} />
          <SidebarItem title="Crafting Stellar Estimations" path="/dashboard/estimation/answering" isActive={location.pathname === '/dashboard/estimation/answering'} />
          <SidebarItem title="Estimation Techniques Toolkit" path="/dashboard/estimation/strategies" isActive={location.pathname === '/dashboard/estimation/strategies'} />
          <SidebarItem title="Estimation Reference" path="/dashboard/estimation/reference" isActive={location.pathname === '/dashboard/estimation/reference'} />
        </CollapsibleSection>

        <CollapsibleSection title="Behavioral & Technical">
          <SidebarItem title="Mastering Behavioral Interviews for PMs" path="/dashboard/behavioral/mastering" isActive={location.pathname === '/dashboard/behavioral/mastering'} />
          <SidebarItem title="Building Your Story Bank" path="/dashboard/behavioral/story-bank" isActive={location.pathname === '/dashboard/behavioral/story-bank'} />
          <SidebarItem title="Intro to Technical Questions" path="/dashboard/technical/intro" isActive={location.pathname === '/dashboard/technical/intro'} />
          <SidebarItem title="Tackling Technical Product Questions" path="/dashboard/technical/tackling" isActive={location.pathname === '/dashboard/technical/tackling'} />
        </CollapsibleSection>
      </div>
    </aside>
  );
};

export default Sidebar;
