import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { Mail, Phone, Linkedin } from 'lucide-react';

const Profile = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      setLoading(true);
      const { data: { user: authUser }, error: authError } = await supabase.auth.getUser();
      
      if (authError) throw authError;
      
      if (authUser) {
        const { data, error } = await supabase
          .from('user_profiles')
          .select('*')
          .eq('user_id', authUser.id)
          .single();

        if (error) throw error;

        setUser({ ...authUser, ...data });
      } else {
        throw new Error('No authenticated user found');
      }
    } catch (error) {
      console.error('Error fetching user profile:', error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (error) {
    return <div className="flex justify-center items-center h-screen text-red-500">Error: {error}</div>;
  }

  if (!user) {
    return <div className="flex justify-center items-center h-screen">No user data available</div>;
  }

  const getInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : '?';
  };

  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="bg-indigo-600 p-6">
          <div className="flex items-center">
            <div className="w-16 h-16 rounded-full bg-white flex items-center justify-center text-indigo-600 text-2xl font-bold mr-4">
              {getInitial(user.name)}
            </div>
            <div>
              <h1 className="text-2xl font-semibold text-white">Hello, {user.name}</h1>
            </div>
          </div>
        </div>
        <div className="p-6">
          <div className="space-y-3">
            <ProfileItem icon={<Mail className="w-5 h-5" />} label="Email" value={user.email} />
            <ProfileItem icon={<Phone className="w-5 h-5" />} label="Phone" value={user.phone_number} />
            <ProfileItem 
              icon={<Linkedin className="w-5 h-5" />} 
              label="LinkedIn" 
              value={user.linkedin_profile}
              isLink
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ProfileItem = ({ icon, label, value, isLink = false }) => (
  <div className="flex items-start">
    <div className="flex-shrink-0 text-gray-500 mr-3 mt-1">
      {icon}
    </div>
    <div>
      <p className="text-sm font-medium text-gray-500 mb-1">{label}</p>
      {isLink && value ? (
        <a href={value} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-800 break-all">
          {value}
        </a>
      ) : (
        <p className="text-base font-medium text-gray-900">{value || 'N/A'}</p>
      )}
    </div>
  </div>
);

export default Profile;