// src/UserContext.js
import React, { createContext, useState, useEffect } from 'react';
import { supabase } from './supabaseClient';

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Check if a user is currently signed in
    const getUser = async () => {
      const {
        data: { user },
        error
      } = await supabase.auth.getUser();
      
      if (!error) {
        setUser(user);
      }
    };

    getUser();

    // Set up a listener for auth changes
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setUser(session?.user ?? null);
    });

    // Cleanup the listener when component unmounts
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider value={{ user }}>
      {children}
    </UserContext.Provider>
  );
};
