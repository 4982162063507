import React from 'react';
import withCourseNavigation from '../hoc/withCourseNavigation';

const ProductDesignAnswerGuide = () => {
  return (
    <div className="course-content">
      <h1 className="course-title">How to Answer Product Design Questions</h1>

      <p className="course-intro">
        In product management interviews, you'll often face questions that challenge you to design or improve a product. Some common examples include:
      </p>
      <ul className="bullet-list">
        <li><strong>Design a product:</strong> "Design an app to help job seekers connect with employers."</li>
        <li><strong>Improve a product:</strong> "How would you improve PhonePe for users in rural areas?"</li>
        <li><strong>Design for a specific audience:</strong> "Create a vending machine that blind users can easily operate."</li>
        <li><strong>Moonshots (big, bold ideas):</strong> "Imagine you have unlimited resources. How would you redesign the entire public transportation system in India?"</li>
      </ul>

      <p className="course-intro">
        The main goal is to propose a product that solves a real problem in a way that makes sense for both the users and the company. When we say the product should be "feasible," it doesn't mean it has to be simple—it should address user needs in a practical, thoughtful way that aligns with the company's goals.
      </p>

      <p className="course-intro">
        Expect these questions to take around 25 minutes to tackle, with follow-up questions afterward. These types of product questions help interviewers see whether you have the essential product skills, and they can come up early in the process or during more in-depth on-site interviews.
      </p>

      <section className="interview-section">
        <h2 className="section-title">A Framework for Answering Product Design Questions</h2>
        <p>
          When answering product design questions, it's helpful to follow a structured process. This 7-step framework will guide you:
        </p>
        <ol className="numbered-list">
          <li><strong>Clarify and get context:</strong> Make sure you fully understand the problem.</li>
          <li><strong>Define the users:</strong> Identify the different groups of users involved.</li>
          <li><strong>Identify pain points and opportunity areas:</strong> What are the challenges these users face?</li>
          <li><strong>Brainstorm possible solutions:</strong> Come up with several ideas to address the pain points.</li>
          <li><strong>Define a product vision:</strong> Paint a long-term vision for your product.</li>
          <li><strong>Prioritize features:</strong> Focus on the most important features first.</li>
          <li><strong>Evaluate and recap:</strong> Summarize and discuss the trade-offs and next steps.</li>
        </ol>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Example Question: "Design a better streaming experience for Hotstar users."</h2>
        
        <div className="step-explanation">
          <h3 className="subsection-title">Step 1: Clarify and get context</h3>
          <p>Start by clarifying the problem. You could ask questions like:</p>
          <ul className="bullet-list">
            <li>"Are we improving the experience for a specific type of content, like sports, movies, or TV shows?"</li>
            <li>"Are there specific pain points we should focus on, such as buffering or content discovery?"</li>
            <li>"Is this improvement for all users, or are we targeting a specific group, like premium subscribers?"</li>
          </ul>
          <p>Let's assume the interviewer gives you a broad prompt and says you're free to improve the overall experience for any user group.</p>
        </div>

        <div className="step-explanation">
          <h3 className="subsection-title">Step 2: Define users</h3>
          <p>Now, break down the users. For Hotstar, here are some potential segments:</p>
          <ul className="bullet-list">
            <li>Sports enthusiasts who primarily watch live sports like cricket matches</li>
            <li>Binge-watchers who prefer TV shows and stream multiple episodes in one sitting</li>
            <li>Casual viewers who watch movies or shows occasionally</li>
            <li>Kids and family audiences who need parental controls and kid-friendly content</li>
          </ul>
          <p>Let's focus on sports enthusiasts. These users care about live streaming quality, real-time updates, and a seamless experience while watching sports events.</p>
        </div>

        <div className="step-explanation">
          <h3 className="subsection-title">Step 3: Identify pain points and opportunity areas</h3>
          <p>Think about the goals of sports enthusiasts. They want uninterrupted live streaming, instant updates, and the ability to watch matches even in low network conditions.</p>
          <p>Here are some possible pain points:</p>
          <ul className="bullet-list">
            <li>Buffering during live matches, especially in areas with poor internet connectivity</li>
            <li>Delays in real-time match statistics or updates</li>
            <li>Difficulty in finding relevant highlights or match recaps after the game</li>
            <li>Limited engagement features like real-time chat or interactions with other fans during the game</li>
          </ul>
          <p>These pain points present opportunities for improvement, such as enhancing the streaming experience in low-bandwidth conditions or adding interactive features for fans.</p>
        </div>

        <div className="step-explanation">
          <h3 className="subsection-title">Step 4: Brainstorm possible solutions</h3>
          <p>Now, brainstorm ideas that could solve these pain points. Here are a few solutions:</p>
          <ul className="bullet-list">
            <li>Adaptive streaming technology that optimizes video quality based on the user's internet speed to ensure a buffer-free experience</li>
            <li>Real-time fan engagement features, such as polls, live chats, or interactive commentary during the match</li>
            <li>Instant match highlights feature, allowing users to access key moments during or after the live stream</li>
            <li>Offline viewing for sports content, enabling users to download match highlights or even entire games for later viewing in low-network areas</li>
          </ul>
        </div>

        <div className="step-explanation">
          <h3 className="subsection-title">Step 5: Define a forward-looking product vision</h3>
          <p>Pick the strongest solution from your brainstorm and envision what it could look like in the future:</p>
          <blockquote className="example-quote">
            "In the future, Hotstar will deliver a seamless sports experience where fans can watch live matches without worrying about buffering, engage with other fans in real-time, and access match highlights instantly—even on slow networks."
          </blockquote>
        </div>

        <div className="step-explanation">
          <h3 className="subsection-title">Step 6: Prioritize features</h3>
          <p>Now, prioritize the features that support your product vision. You might suggest:</p>
          <ul className="bullet-list">
            <li>Adaptive streaming to ensure uninterrupted live sports even with slow internet</li>
            <li>Instant highlights that allow fans to watch key moments without waiting for the full match to finish</li>
            <li>Real-time engagement tools, such as polls, live chats, or fan voting during matches</li>
          </ul>
          <p>These features directly solve the pain points of sports enthusiasts, improving their overall experience with Hotstar.</p>
        </div>

        <div className="step-explanation">
          <h3 className="subsection-title">Step 7: Evaluate and recap</h3>
          <p>Finally, wrap up by summarizing your solution and discussing any trade-offs:</p>
          <blockquote className="example-quote">
            "One trade-off we'd need to consider is ensuring that real-time chat or engagement features don't distract users from the match itself. We'd also need to make sure that adaptive streaming maintains decent video quality even in low-bandwidth conditions to avoid a subpar viewing experience."
          </blockquote>
        </div>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Tips for Answering Product Design Questions</h2>
        <ul className="bullet-list">
          <li><strong>Understand company strategy:</strong> Think about how your product aligns with Hotstar's broader mission, such as providing the best live-streaming experience for sports fans.</li>
          <li><strong>Ace moonshot questions:</strong> Don't be afraid to think big. Imagine what a flawless sports experience would look like and how to eliminate every possible issue.</li>
          <li><strong>Don't rush to a solution:</strong> Take your time explaining your thought process. Interviewers want to see how you think about the problem, not just the final answer.</li>
          <li><strong>Segment users carefully:</strong> Even though Hotstar has millions of users, segmenting them into meaningful groups like sports fans, binge-watchers, or casual viewers helps address specific needs.</li>
          <li><strong>Avoid designing for everyone:</strong> Focus on a particular group to avoid creating a solution that's too broad and doesn't address specific pain points effectively.</li>
        </ul>
      </section>
    </div>
  );
};

export default withCourseNavigation(ProductDesignAnswerGuide);
