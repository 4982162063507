import React from 'react';
import withCourseNavigation from '../hoc/withCourseNavigation';

const MetricsQuestionsAnswerGuide = () => {
  return (
    <div className="course-content">
      <h1 className="course-title">How to Answer Metrics Questions</h1>

      <p className="course-intro">
        Metrics questions are common in PM interviews. The scope of these questions can range from defining a north star metric for a product or company to identifying key performance indicators (KPIs) for a specific feature.
      </p>

      <section className="interview-section">
        <h2 className="section-title">Example Questions</h2>
        <ul className="bullet-list">
          <li>"Define a north star metric for Paytm's wallet feature."</li>
          <li>"What metrics would you track if you were launching a new feature at Swiggy?"</li>
          <li>"How do you define success metrics for a new lending product at PhonePe?"</li>
        </ul>
        <p>
          A <strong>North Star Metric (NSM)</strong> is the single most important metric that reflects the core value your product delivers to customers and aligns your team around a shared goal. It measures the long-term impact of your product and drives sustainable growth by focusing on customer success.
        </p>
      </section>

      <div className="image-container mb-6">
          <img 
            src="https://i.imgur.com/a1rYT16.png" 
            alt="Skills for product managers" 
            className="w-full h-auto rounded-lg"
          />
      </div>

      <section className="interview-section">
        <h2 className="section-title">What Interviewers are Looking For</h2>
        <p>Interviewers expect you to:</p>
        <ul className="bullet-list">
          <li>Set feature, product, or company goals.</li>
          <li>Identify relevant metrics that align with those goals.</li>
          <li>Apply strategic reasoning to explain why your chosen metrics matter.</li>
        </ul>
        <p>
          A strong answer demonstrates that you understand what success means for the feature or product and can translate that into actionable metrics. The key is to start by defining the larger goal and then narrow it down to specific metrics.
        </p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">The GAME Framework for Answering Metrics Questions</h2>
        <p>Use the GAME framework to structure your answer clearly. The framework consists of five steps:</p>

        <h3 className="subsection-title">1. Clarify</h3>
        <p>
          Start by clarifying any ambiguity in the question. Ask for any necessary details about the product or feature and its goals. This ensures that you're aligned with the interviewer from the beginning.
        </p>
        <p><strong>Example</strong> For a question like "What metrics would you track for Flipkart's homepage redesign?", you might ask:</p>
        <ul className="bullet-list">
          <li>"Should I be focusing on the overall user journey or just homepage interactions?"</li>
          <li>"Are there specific business goals like increasing conversion rates?"</li>
        </ul>

        <h3 className="subsection-title">2. Goals</h3>
        <p>
          Define the key goals for the product or feature. Think about the product's mission and the desired user behavior. What is the product designed to achieve, and how does that relate to the company's overall strategy?
        </p>
        <p><strong>Example:</strong> Let's say you're defining success metrics for Swiggy's subscription service, Swiggy One. A relevant goal might be:</p>
        <p>"The goal of Swiggy One is to increase customer loyalty and drive repeat orders by offering benefits like free delivery and exclusive offers."</p>

        <h3 className="subsection-title">3. Actions</h3>
        <p>
          Once you've identified the goals, outline the actions you want users to take. What user behaviors will indicate that the product is meeting its goals? This is where building a user journey map can help.
        </p>
        <p><strong>Example :</strong> For Swiggy One, key user actions might include:</p>
        <ul className="bullet-list">
          <li>Users subscribing to the service.</li>
          <li>Subscribers placing more frequent orders.</li>
          <li>Subscribers using exclusive offers more than non-subscribers.</li>
        </ul>

        <h3 className="subsection-title">4. Metrics</h3>
        <p>
          Now, choose the metrics that best measure the key actions you've identified. Explain your thought process to the interviewer, and justify why you're choosing certain metrics over others.
        </p>
        <p><strong>Common Metrics:</strong></p>
        <ul className="bullet-list">
          <li><strong>Product Metrics:</strong> These capture how users interact with the product (e.g., DAU, CTR, session time).</li>
          <li><strong>Business Metrics:</strong> These track overall company performance (e.g., revenue, profit margin, customer lifetime value).</li>
          <li><strong>OKRs:</strong> Objectives and Key Results connect strategic objectives to measurable outcomes.</li>
          <li><strong>Quality Metrics:</strong> These monitor product or process quality (e.g., bug backlog, page load time).</li>
          <li><strong>Leading Indicators:</strong> Early signs that show if a feature is working well (e.g., user sign-ups, button clicks).</li>
          <li><strong>Counter Metrics:</strong> Used to ensure that driving one metric doesn't negatively impact others (e.g., churn rate, customer satisfaction).</li>
        </ul>
        <p><strong>Example  :</strong> For Swiggy One, your key metrics might include:</p>
        <ul className="bullet-list">
          <li>Subscription rate (how many users subscribe to Swiggy One).</li>
          <li>Frequency of orders by subscribers vs. non-subscribers.</li>
          <li>Average order value of subscribers.</li>
          <li>Churn rate (how many subscribers cancel after one month).</li>
        </ul>

        <h3 className="subsection-title">5. Evaluate</h3>
        <p>
          Once you've chosen your metrics, evaluate them. Are they comprehensive enough? Do they have any weaknesses? What trade-offs might they involve?
        </p>
        <p><strong>Example  :</strong> For Swiggy One, you might say:</p>
        <p>
          "While subscription rate is important, it could be misleading if many users subscribe but don't place additional orders. Therefore, I would couple it with metrics like order frequency and churn rate to ensure that the subscription is driving long-term customer value."
        </p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Example: Defining Metrics for Zomato's Subscription Service (Zomato Gold)</h2>
        <p>Let's work through an example to illustrate how to use the GAME framework for Zomato Gold, a subscription service offering benefits like free delivery and discounts.</p>
        <p><strong>Question:</strong> "How would you measure the success of Zomato Gold?"</p>

        <h3 className="subsection-title">Step 1: Clarify</h3>
        <p>"Should I focus only on Zomato Gold's subscription feature, or should I consider its impact on the entire Zomato platform?"</p>

        <h3 className="subsection-title">Step 2: Goals</h3>
        <p>"The goal of Zomato Gold is to increase user retention and loyalty by offering exclusive perks. It's designed to drive more orders from loyal customers and reduce churn."</p>

        <h3 className="subsection-title">Step 3: Actions</h3>
        <ul className="bullet-list">
          <li>Users subscribing to Zomato Gold.</li>
          <li>Subscribers ordering more frequently than non-subscribers.</li>
          <li>Subscribers engaging more with the platform (e.g., using offers, leaving reviews).</li>
        </ul>

        <h3 className="subsection-title">Step 4: Metrics</h3>
        <ul className="bullet-list">
          <li>Subscription rate: How many users are subscribing to Zomato Gold?</li>
          <li>Order frequency: How often do Zomato Gold subscribers order compared to non-subscribers?</li>
          <li>Churn rate: How many subscribers cancel after the first month?</li>
          <li>Average order value of Zomato Gold users vs. non-subscribers.</li>
          <li>Net Promoter Score (NPS) among Zomato Gold users.</li>
        </ul>

        <h3 className="subsection-title">Step 5: Evaluate</h3>
        <p>
          "While subscription rate is useful, it could be misleading if many users subscribe but don't order more frequently. Therefore, I would focus on order frequency and churn rate to ensure Zomato Gold is driving customer retention and long-term engagement."
        </p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">"Okay" vs. "Good" vs. "Great" Answers</h2>
        <ul className="bullet-list">
          <li><strong>Okay:</strong> The candidate provides a basic list of metrics without explaining how they tie into the product's goals.</li>
          <li><strong>Good:</strong> The candidate clearly links metrics to product goals and explains why each metric matters. They can identify potential blind spots and suggest guardrails.</li>
          <li><strong>Great:</strong> The candidate demonstrates deep product knowledge, ties the north star metric to the company's overall strategy, and thinks critically about the trade-offs involved. They provide a comprehensive, structured answer with relevant insights.</li>
        </ul>
      </section>

      <p className="course-conclusion">
        By using the GAME framework, you can confidently approach any metrics question and demonstrate your ability to think strategically about product success.
      </p>

    </div>
  );
};

export default withCourseNavigation(MetricsQuestionsAnswerGuide);