// src/components/Introduction/QuestionTypes.js
import React from 'react';
import withCourseNavigation from '../hoc/withCourseNavigation';

const PMInterviewQuestionTypes = () => {
  return (
    <div className="">
      <h1 className="course-title">PM Interview Questions Types</h1>

      <p className="course-intro">
        Interview questions will vary depending on the company (industry, domain, job description etc), but there are certain types of questions which are almost guaranteed. These are designed to see how well you can design products, solve complex problems, create effective strategies, and make decisions based on data or we would say ambigious problems with less data.
      </p>

      <p className="course-intro">
        In addition to problem-solving, expect to get a few questions about your background and culture fit, like "Tell me about yourself" or "What's your favourite app?"
        These give the interviewer a sense of how you think, what you value as a PM, and how well you align with the company's culture. Now culture is very subjective and it's difficult to understand what the hiring manager is looking in this round. One of the common thing that we have learned here is just be true, honest and curious in culture fit rounds. 
      </p>

      <p className="course-intro">
        Let's dive into the main categories of questions you'll face and how you can tackle them. We have covered some frameworks to make things easier for you. Having said that, don't rely too much on the frameworks. Idea of frameworks is to provide structure around how to think and come up with better ideas while solving problems.
      </p>
      <div className="image-container mb-6">
          <img 
            src="https://i.imgur.com/Dvj1j1j.png" 
            alt="Skills for product managers" 
            className="w-full md:w-3/4 h-auto rounded-lg"
          />
        </div>

      <section className="interview-section">
        <h2 className="section-title">1. Product Design Questions</h2>
        <p>
          Designing great products is at the core of what a product manager does everyday. These questions will test how you think about user needs, prioritize features, and create solutions that are not just useful but scalable. Your task is to demonstrate that you understand the user and can design a product that solves their real-world problems.
        </p>
        <h3 className="subsection-title">Key Focus Areas:</h3>
        <ul className="bullet-list">
          <li><strong>Understanding user pain points:</strong> Can you put yourself in the user's shoes and identify their biggest challenges?</li>
          <li><strong>Prioritizing features:</strong> How do you decide which features are most important to work on first?</li>
          <li><strong>Iterating on feedback:</strong> How do you improve your product over time based on user feedback and performance metrics?</li>
        </ul>
        <h3 className="subsection-title">Example Questions:</h3>
        <ul className="bullet-list">
          <li>"Design an app to help farmers in rural India track weather changes."</li>
          <li>"How would you design a product that helps local shops compete with large e-commerce platforms?"</li>
          <li>"What features would you add to Paytm to encourage more digital transactions in tier-2 cities?"</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">2. Product Strategy Questions</h2>
        <p>
          A good product strategy ensures the product not only solves user problems but also drives business growth. Strategy questions evaluate your ability to think long-term and align product decisions with company objectives. Whether it's expanding into new markets or figuring out pricing strategies, these questions will test how well you can think strategically.
        </p>
        <h3 className="subsection-title">Key Focus Areas:</h3>
        <ul className="bullet-list">
          <li><strong>Market analysis:</strong> Can you identify growth opportunities and market trends?</li>
          <li><strong>Competitive positioning:</strong> How does your product stand out in a crowded market?</li>
          <li><strong>Business impact:</strong> How do your decisions drive revenue or user growth?</li>
        </ul>
        <h3 className="subsection-title">Example Questions:</h3>
        <ul className="bullet-list">
          <li>"Should Zomato introduce a subscription model for premium restaurants?"</li>
          <li>"What's the biggest threat to Amazon in India?"</li>
          <li>"You're a PM at Ola. How would you strategize the launch of an electric scooter line across major cities?"</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">3. Execution Questions</h2>
        <p>
          Execution is all about how you turn ideas into action. These questions typically focus on how well you can handle problems that arise during product development, make data-driven decisions, and manage teams to deliver results. You might be asked how you'd react to a sudden drop in app performance or how you'd prioritize tasks when multiple teams are involved.
        </p>
        <h3 className="subsection-title">Key Focus Areas:</h3>
        <ul className="bullet-list">
          <li><strong>Problem-solving:</strong> Can you quickly identify the root cause of an issue?</li>
          <li><strong>Team management:</strong> How do you ensure everyone stays on track to meet deadlines?</li>
          <li><strong>Decision-making under pressure:</strong> How do you make tough choices when things don't go as planned?</li>
        </ul>
        <h3 className="subsection-title">Example Questions:</h3>
        <ul className="bullet-list">
          <li>"The payment failure rate on Razorpay has spiked by 15%. What do you do?"</li>
          <li>"As a PM at Swiggy, delivery times have increased by 10%. How would you address this?"</li>
          <li>"You're managing a new feature launch at Flipkart, but you're behind schedule. How do you prioritize tasks to ensure an on-time launch?"</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">4. Analytical Questions</h2>
        <p>
          A solid product manager relies heavily on data to guide decisions. Analytical questions are designed to test your ability to look at metrics, interpret data, and decide on the next steps based on what the numbers are telling you. These questions often overlap with execution, as you'll be asked to solve problems using data-driven insights.
        </p>
        <h3 className="subsection-title">Key Focus Areas:</h3>
        <ul className="bullet-list">
          <li><strong>Choosing the right metrics:</strong> How do you select the most important KPIs to track?</li>
          <li><strong>Running experiments:</strong> How do you use A/B testing to validate a hypothesis?</li>
          <li><strong>Interpreting data:</strong> Can you take a set of metrics and decide the best course of action?</li>
        </ul>
        <h3 className="subsection-title">Example Questions:</h3>
        <ul className="bullet-list">
          <li>"As a PM for PhonePe, what metrics would you track to improve user engagement with UPI transactions?"</li>
          <li>"How would you measure the success of a new feature in BigBasket's grocery app?"</li>
          <li>"Design an A/B test to improve user retention on an OTT platform like Hotstar."</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">5. Estimation Questions</h2>
        <p>
          Estimation questions might seem intimidating, but they're a chance to show how you think through problems methodically. These questions aren't about getting the exact answer but about showing how you approach a complex problem step by step. Whether you're estimating the size of a market or the number of users for a new feature, you'll need to break the problem into manageable pieces.
        </p>
        <h3 className="subsection-title">Key Focus Areas:</h3>
        <ul className="bullet-list">
          <li><strong>Breaking down big problems:</strong> Can you deconstruct a problem into smaller, solvable parts?</li>
          <li><strong>Making reasonable assumptions:</strong> Are your assumptions logical and based on real-world knowledge?</li>
          <li><strong>Providing a logical estimate:</strong> How do you back up your final estimate with reasoning?</li>
        </ul>
        <h3 className="subsection-title">Example Questions:</h3>
        <ul className="bullet-list">
          <li>"Estimate the number of kirana stores in Mumbai."</li>
          <li>"How many Swiggy orders are placed in India on a busy weekend?"</li>
          <li>"What's the estimated market size for electric vehicles in India by 2030?"</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">6. Behavioral Questions</h2>
        <p>
          Behavioral questions give the interviewer a sense of your personality, work style, and how well you handle common PM situations. You'll be asked to recount past experiences, particularly how you've dealt with challenges, conflicts, or successes in your previous roles. It's your chance to show your leadership style and how you contribute to team dynamics.
        </p>
        <h3 className="subsection-title">Key Focus Areas:</h3>
        <ul className="bullet-list">
          <li><strong>Handling conflict:</strong> How do you resolve disagreements within your team or with stakeholders?</li>
          <li><strong>Learning from experience:</strong> What have you learned from successes and failures in your past roles?</li>
          <li><strong>Collaboration and communication:</strong> How do you work with cross-functional teams to deliver products?</li>
        </ul>
        <h3 className="subsection-title">Example Questions:</h3>
        <ul className="bullet-list">
          <li>"Tell me about a time you had to persuade leadership to adopt your product vision."</li>
          <li>"How did you handle a situation where you disagreed with your engineering team on a product feature?"</li>
          <li>"What's your biggest strength as a PM, and how does it help you in your role?"</li>
        </ul>
      </section>

      <section className="interview-section">
        <h2 className="section-title">7. Favorite Product Questions</h2>
        <p>
          "What's your favorite product?" is a classic PM interview question designed to understand your thought process, product sense, and what excites you as a PM. It's less about the product itself and more about how you think through its design, impact, and market positioning.
        </p>
        <h3 className="subsection-title">How to Approach:</h3>
        <ul className="bullet-list">
          <li><strong>Passion:</strong> Choose a product you genuinely enjoy using.</li>
          <li><strong>Product knowledge:</strong> Be ready to explain why this product stands out and what makes it successful.</li>
          <li><strong>Unique perspective:</strong> Offer a thoughtful critique—what could be improved, and why?</li>
        </ul>
      </section>

    </div>
  );
};

export default withCourseNavigation(PMInterviewQuestionTypes);