import React from 'react';
import withCourseNavigation from '../hoc/withCourseNavigation';

const StrategyQuestionsGuide = () => {
  return (
    <div className="course-content">
      <h1 className="course-title">How to Answer Strategy Questions</h1>

      <p className="course-intro">
        Strategy questions in PM interviews can feel a bit daunting. They typically run 45 minutes to an hour, and the goal isn't just to arrive at a solid strategy but to show your understanding of the product, market, and industry. A good answer gives a clear, defensible strategy. A great answer goes deeper, uncovering new insights and using those insights to not only solve the current problem but to open up future opportunities.
      </p>

      <p className="course-intro">
        You should also set aside time to discuss what you'd do with more information, how you'd handle risks, and how your strategy would evolve in the long run. This shows that you can think critically, even beyond the immediate situation.
      </p>

      <p className="course-intro">
        Don't worry about solving everything in 40 minutes—what interviewers really care about is your process.
      </p>

      <section className="interview-section">
        <h2 className="section-title">Framework for Answering Product Strategy Questions</h2>
        <p>Here's a seven-step framework you can use to answer product strategy questions:</p>
        <ol className="numbered-list">
          <li>Clarify the problem</li>
          <li>Define the product, company, or industry goals</li>
          <li>Define the landscape</li>
          <li>Establish guiding principles</li>
          <li>Brainstorm options</li>
          <li>Make your decision and defend it</li>
          <li>Evaluate and recap</li>
        </ol>
        <p>You'll notice that you don't start generating options until step 5. That's intentional! You need to first understand the problem, the landscape, and the goals before jumping into solutions. In fact, it's smart to spend about 50% of your time on steps 1 through 4.</p>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Step-by-Step Example: What's the biggest threat to Instagram over the next 5 years?</h2>
        
        <div className="step-explanation">
          <h3 className="subsection-title">Step 1: Clarify the Problem</h3>
          <p>Start by asking clarifying questions to make sure you and the interviewer are aligned. In this case, you might ask:</p>
          <ul className="bullet-list">
            <li>"By Instagram, are we focusing just on the core photo-sharing platform, or does this include features like Instagram Reels and Stories?"</li>
            <li>"Are we looking at global threats, or just in specific regions?"</li>
          </ul>
          <p>Let's assume the interviewer confirms that the question is about Instagram globally, including all its major features like Reels, Stories, and the core feed.</p>
        </div>

        <div className="step-explanation">
          <h3 className="subsection-title">Step 2: Define the Product, Company, or Industry Goals</h3>
          <p>Next, define Instagram's main goals. What drives its success?</p>
          <p>You might say:</p>
          <ul className="bullet-list">
            <li>"Instagram's primary goal is to increase user engagement and time spent on the platform. This engagement is what drives its ad revenue, which is the core of its business model."</li>
            <li>"To maintain its position, Instagram needs to strengthen the creator-viewer relationship, as creators generate content that keeps users engaged."</li>
            <li>"Additionally, Instagram needs to stay competitive in the fast-moving world of social media, where trends shift quickly and new platforms can emerge at any time."</li>
          </ul>
          <p>In short, Instagram's key goal is to increase user engagement while staying ahead of competitors.</p>
        </div>

        <div className="step-explanation">
          <h3 className="subsection-title">Step 3: Define the Landscape</h3>
          <p>Now, think about the external factors that could impact Instagram. These might include:</p>
          <ul className="bullet-list">
            <li><strong>Competition:</strong> Are there emerging competitors like TikTok that are pulling users and creators away?</li>
            <li><strong>User behavior:</strong> Are there shifts in how people consume content (e.g., more short-form video, less interest in static photos)?</li>
            <li><strong>Technology:</strong> Could advancements in AR/VR or AI change how people interact with social media?</li>
            <li><strong>Regulation:</strong> Are there privacy or data security concerns that could impact how Instagram operates?</li>
          </ul>
          <p>Given that short-form video platforms like TikTok have been growing rapidly, this could be one of the key areas to focus on.</p>
        </div>

        <div className="step-explanation">
          <h3 className="subsection-title">Step 4: Define Guiding Principles</h3>
          <p>Guiding principles help you make strategic decisions. For Instagram, these might be:</p>
          <ul className="bullet-list">
            <li>"Instagram's success depends on high engagement, driven by the creator-viewer relationship."</li>
            <li>"Short-form video content is becoming increasingly important, as platforms like TikTok are capturing more attention."</li>
            <li>"Instagram's strength lies in its ability to balance user-generated content with curated content from creators."</li>
          </ul>
        </div>

        <div className="step-explanation">
          <h3 className="subsection-title">Step 5: Establish an Option Set</h3>
          <p>Now it's time to brainstorm potential threats to Instagram. Based on the guiding principles, you could list:</p>
          <ul className="bullet-list">
            <li><strong>Direct competitors:</strong> TikTok, YouTube Shorts, and Snapchat are growing in popularity with younger users who prefer short-form video.</li>
            <li><strong>Indirect competitors:</strong> Other platforms like Twitter, Reddit, and even gaming platforms like Twitch could take away user attention, though they don't directly compete for visual content.</li>
            <li><strong>Emerging technologies:</strong> AR/VR platforms could shift how people interact with social media, making traditional platforms like Instagram feel outdated.</li>
          </ul>
          <p>After considering these, you might focus on TikTok as the primary threat because of its rapid growth, particularly in short-form video, which is an area Instagram is also targeting with Reels.</p>
        </div>

        <div className="step-explanation">
          <h3 className="subsection-title">Step 6: Make Your Decision and Defend It</h3>
          <p>Now, choose what you think is Instagram's biggest threat and justify it. Let's say you decide that TikTok is the biggest threat:</p>
          <blockquote className="example-quote">
            "While Instagram has a strong foothold in photo-sharing and a large user base, TikTok is growing quickly, especially among younger users, with its algorithm-driven short-form video content. TikTok's ability to keep users engaged for long periods with a continuous stream of personalized videos is a direct challenge to Instagram's Reels feature."
          </blockquote>
          <p>You might also mention TikTok's appeal to creators:</p>
          <blockquote className="example-quote">
            "Creators on TikTok are able to quickly build large followings, and the platform's monetization options are growing. If TikTok continues to attract top creators, Instagram could struggle to maintain its dominance in the social media space."
          </blockquote>
        </div>

        <div className="step-explanation">
          <h3 className="subsection-title">Step 7: Evaluate and Recap</h3>
          <p>To wrap up, recap your answer and discuss what you might do with more time or information. You could also talk about risks or trade-offs involved in your analysis.</p>
          <p>For example:</p>
          <blockquote className="example-quote">
            "If I had more time, I'd want to explore how Instagram can differentiate itself from TikTok and strengthen its core user base. One option could be improving Reels to better integrate with Instagram's broader ecosystem, allowing users to discover new content from creators they already follow. However, there's always a risk that focusing too much on short-form video could alienate Instagram's older users, who might prefer photo content."
          </blockquote>
        </div>
      </section>

      <section className="interview-section">
        <h2 className="section-title">Common Pitfalls</h2>
        <ul className="bullet-list">
          <li><strong>Set context:</strong> Always clarify the problem and set the stage for your answer. Don't rush to a solution.</li>
          <li><strong>Structure your answer:</strong> Using a framework like this helps you stay organized and ensures you cover all the important points.</li>
          <li><strong>Answer the question:</strong> Make sure you're driving towards a concrete answer, rather than getting lost in analysis.</li>
          <li><strong>Discuss trade-offs:</strong> Most strategic decisions come with trade-offs. Highlight them and explain why your decision makes sense despite those risks.</li>
        </ul>
      </section>

    </div>
  );
};

export default withCourseNavigation(StrategyQuestionsGuide);
